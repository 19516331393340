import { debounce } from '../../../../Foundation/Core/code/Scripts/utilities/timing';

const actions = {
  getNextQuestion: debounce(async ({ commit, dispatch, getters }) => {
    const { currentAnswer, endpoint } = getters;
    commit('setProcessingAnswer', true);

    await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      credentials: 'same-origin',
      body: JSON.stringify({ AnswerId: currentAnswer.id, TreeId: currentAnswer.treeId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Success) {
          commit('setErrorMessage', '');
        } else {
          throw data;
        }

        if (data.Data.Results) {
          commit('setResult', data.Data.Results);
          commit('updateDisplay', 'result');
        }

        dispatch('historyForward', {
          answer: currentAnswer,
          nextOptions: data.Data.Options,
        });
      })
      .catch((data) => {
        if (data.StatusCode === 404) {
          commit('setResult', data.Data?.Results);
          commit('updateDisplay', 'result');
        } else if (data.StatusCode === 500) {
          commit('setErrorMessage', data.Message);
          console.error('Could not fetch next question', data.Message);
        }
      })
      .finally(() => {
        commit('setProcessingAnswer', false);
      });
  }, 600),

  goBack: debounce(({ commit, dispatch, getters }) => {
    const { currentStep } = getters;

    if (currentStep === 1) {
      commit('updateDisplay', 'cover');
      return;
    }

    dispatch('historyBack');
  }, 600),

  historyBack: ({ commit, getters, state }) => {
    commit('setCurrentStep', getters.currentStep - 1);

    commit('setCurrentOptions', getters.answersHistory[getters.currentStep - 1].options);
    state.answersHistory.pop();
  },

  historyForward: ({ commit, getters, state }, { answer, nextOptions }) => {
    commit('setCurrentStep', getters.currentStep + 1);

    state.answersHistory.push({
      answer,
      options: getters.currentOptions,
    });

    commit('setCurrentOptions', nextOptions);
  },

  setupStore: ({ commit }, payload) => {
    const { currentOptions, endpoint, questions } = payload;

    commit('setCurrentOptions', currentOptions);
    commit('setCurrentQuestion', questions[0]);
    commit('setEndpoint', endpoint);
    commit('setQuestions', questions);
  },

  startOver: ({ commit }) => {
    commit('updateDisplay', 'cover');
    commit('resetQuiz');
  },

  updateCurrentAnswer({ commit, getters }, payload) {
    const selectedOption = getters.currentOptions.find((option) => option.id === payload);

    commit('setCurrentAnswer', selectedOption);
  },

  updateDisplay({ commit }, payload) {
    commit('updateDisplay', payload);
  },
};

const getters = {
  answersHistory: (state) => state.answersHistory,
  currentAnswer: (state) => state.currentAnswer,
  currentOptions: (state) => state.currentOptions,
  currentQuestion: (state) => state.currentQuestion,
  currentStep: (state) => state.currentStep,
  display: (state) => state.display,
  processingAnswer: (state) => state.processingAnswer,
  result: (state) => state.result,
  endpoint: (state) => state.endpoint,
  errorMessage: (state) => state.errorMessage,
};

const mutations = {
  resetQuiz: (state) => {
    state.answersHistory = [];
    state.currentAnswer = null;
    state.currentQuestion = {};
    state.currentStep = 1;
    state.result = null;
  },

  setProcessingAnswer: (state, payload) => {
    state.processingAnswer = payload;
  },

  setResult: (state, payload) => {
    state.result = payload;
  },

  setCurrentAnswer: (state, payload) => {
    state.currentAnswer = payload;
  },

  setCurrentOptions: (state, payload) => {
    state.currentOptions = payload;
  },

  setCurrentQuestion: (state, payload) => {
    state.currentQuestion = payload;
  },

  setCurrentStep: (state, payload) => {
    state.currentAnswer = null;
    state.currentQuestion = state.questions[payload - 1];
    state.currentStep = payload;
  },

  setEndpoint: (state, payload) => {
    state.endpoint = payload;
  },

  setErrorMessage: (state, payload) => {
    state.errorMessage = payload;
  },

  setQuestions: (state, payload) => {
    state.questions = payload;
  },

  updateDisplay: (state, payload) => {
    state.display = payload;
  },
};

const state = {
  answersHistory: [],
  currentAnswer: null,
  currentOptions: [],
  currentQuestion: {},
  currentStep: 1,
  display: 'cover',
  result: null,
  errorMessage: '',
  endpoint: '',
  processingAnswer: false,
  questions: [],
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
