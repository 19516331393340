<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="oap-msm">
    <div class="oap-msm__modal">
      <div
        ref="msm-app"
        :class="[
          {
            'stop-scroll': showPopIn || vtoState.mode,
            'vto-mode': vtoState.mode,
            'vto-transition': vtoState.transition,
            'vto-loading': vtoState.mode && !vtoState.ready,
            'vto-ready': vtoState.mode && vtoState.ready,
            'vto-ui': showVtoUI,
            'has-started': hasStarted,
          },

          vtoState.mode && vtoState.page ? `vto-page-${vtoState.page}` : '',
          'oap-msm__app',
        ]"
      >
        <div id="oap-msm__vto-frame-container" ref="vto" class="oap-msm__vto-frame-container"></div>

        <div v-if="vtoState.mode" class="oap-msm__vto-compare-labels">
          <span
            :class="{ show: vtoState.beforeAfterBarPosition > 33 }"
            class="oap-msm__vto-compare-label-before"
            >{{ dictionary.vtoCompareBefore }}</span
          >
          <span
            v-if="getCurrentAnswer"
            :class="{ show: vtoState.beforeAfterBarPosition < 66 }"
            class="oap-msm__vto-compare-label-after"
            >{{ getCurrentAnswer.label }}</span
          >
        </div>

        <transition mode="out-in" name="oap-msm__fade">
          <!-- INTRO PAGE -->
          <div v-if="!hasStarted" key="intro">
            <div class="oap-msm__navigation">
              <div class="oap-msm__navigation-buttons">
                <button class="oap-msm__close-button" @click="close()">
                  <svg class="icon">
                    <use xlink:href="#close" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                  </svg>
                  <span class="is-sr-only">{{ dictionary.closeLabel }}</span>
                </button>
              </div>
            </div>

            <div class="oap-msm__intro-image">
              <img
                ref="introImage"
                :alt="introPage.imgAlt"
                :src="introPage.imgSrc"
                @load="isLoaded"
              />
            </div>

            <div class="oap-rich-text rich-text" v-html="introPage.text"></div>
            <button class="oap-button -primary -theme-orange -fluid" @click="start()">
              <span class="oap-button__label">{{ introPage.buttonText }}</span>
            </button>
          </div>

          <!-- EXPERIENCE PAGE -->
          <div v-else key="form">
            <div ref="navigation" class="oap-msm__navigation">
              <div class="oap-msm__navigation-buttons">
                <button
                  :title="dictionary.backLabel"
                  class="oap-msm__back-button"
                  type="button"
                  @click="back()"
                >
                  <svg class="icon">
                    <use xlink:href="#arrow-back" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                  </svg>
                  <span class="is-sr-only">{{ dictionary.backLabel }}</span>
                </button>

                <button
                  :title="dictionary.closeLabel"
                  class="oap-msm__close-button"
                  @click="close()"
                >
                  <svg class="icon">
                    <use xlink:href="#close" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                  </svg>
                  <span class="is-sr-only">{{ dictionary.closeLabel }}</span>
                </button>
              </div>

              <div
                :style="{ '--progress': getCurrentProgress }"
                class="oap-msm__progress-bar"
              ></div>

              <div class="oap-msm__steps">
                <span class="oap-msm__steps-current">
                  0<transition mode="out-in" name="oap-msm__count-down">
                    <span :key="`step-${getCurrentStep}`">{{ getCurrentStep }}</span>
                  </transition>
                </span>
                <span class="oap-msm__steps-total">/ 0{{ totalSteps }}</span>
              </div>
            </div>

            <transition mode="out-in" name="oap-msm__fade">
              <div :key="`form-${getCurrentStep}`" class="oap-msm__form">
                <header class="oap-msm__form-header">
                  <p class="oap-msm__form-header-title">{{ getCurrentQuestion.title }}</p>
                  <p class="oap-msm__form-header-subtitle">{{ getCurrentQuestion.subtitle }}</p>
                </header>

                <div class="oap-msm__answers">
                  <ul class="oap-msm__answers-list">
                    <li
                      v-for="answer of getCurrentQuestion.answers"
                      :key="answer"
                      :class="{ '-no-tip': !answers[answer].tip }"
                      class="oap-msm__answers-item"
                    >
                      <label
                        :ref="`label-${answers[answer].id}`"
                        :aria-selected="answers[answer].id === formData.color ? 'true' : 'false'"
                        :for="answers[answer].id"
                        tabindex="0"
                        @click="selectOption(answers[answer])"
                        @keydown.enter="selectOptionWithEnter(answers[answer].id)"
                      >
                        <input
                          :id="answers[answer].id"
                          v-model="formData[getCurrentQuestion.id]"
                          :value="answers[answer].id"
                          type="radio"
                        />
                        <span class="oap-msm__answers-tile">
                          <img
                            :src="answers[answer].image.fileName"
                            alt=""
                            class="oap-msm__answers-thumbnail"
                          />
                          <span class="oap-msm__answers-label">{{ answers[answer].label }}</span>
                        </span>
                      </label>
                    </li>
                  </ul>

                  <transition mode="out-in" name="oap-msm__fade">
                    <div
                      v-if="getCurrentAnswer && getCurrentAnswer.tip && showTip"
                      :key="`tip-${getCurrentAnswer.id}`"
                      ref="tip"
                      class="oap-msm__tips"
                    >
                      <button
                        v-if="vtoState.mode"
                        :title="dictionary.closeTipLabel"
                        class="oap-msm__tips-close"
                        type="button"
                        @click="closeTip()"
                      >
                        <svg class="icon">
                          <use xlink:href="#close" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                        </svg>
                      </button>
                      <p v-if="getCurrentAnswer.tip.title" class="oap-msm__tips-title">
                        {{ getCurrentAnswer.tip.title }}
                      </p>
                      <p v-if="getCurrentAnswer.tip.text" class="oap-msm__tips-text">
                        {{ getCurrentAnswer.tip.text }}
                      </p>
                    </div>
                  </transition>
                </div>

                <footer class="oap-msm__form-footer">
                  <button
                    v-show="getCurrentStep === 2"
                    ref="vto-button"
                    class="oap-button -primary -fluid oap-msm__vto-button"
                    @click="startVto()"
                  >
                    <span class="oap-button__label">{{ dictionary.vtoButtonLabel }}</span>
                  </button>

                  <button
                    ref="next-button"
                    :disabled="!isFilled"
                    class="oap-button -primary -theme-orange -fluid"
                    @click="next()"
                  >
                    <span class="oap-button__label">{{
                      getCurrentQuestion.customButtonLabel
                    }}</span>
                  </button>
                </footer>
              </div>
            </transition>
          </div>
        </transition>

        <transition name="oap-msm__fade">
          <div v-if="getCurrentPopIn && showPopIn" class="oap-msm__pop-in">
            <button class="oap-msm__pop-in-close" type="button" @click="togglePopIn()">
              <svg class="icon">
                <use xlink:href="#close" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
              </svg>
              <span class="is-sr-only">{{ dictionary.closeLabel }}</span>
            </button>

            <h2 v-if="getCurrentPopIn.header" class="oap-msm__pop-in-header">
              {{ getCurrentPopIn.header }}
            </h2>
            <h3 v-if="getCurrentPopIn.title" class="oap-msm__pop-in-title">
              {{ getCurrentPopIn.title }}
            </h3>
            <p v-if="getCurrentPopIn.description" class="oap-msm__pop-in-text">
              {{ getCurrentPopIn.description }}
            </p>

            <button
              class="oap-button -primary -theme-orange -fluid -expanded"
              @click="next(getCurrentPopIn.answerId)"
            >
              <span class="oap-button__label">
                {{ getCurrentPopIn.buttonText }}
              </span>
            </button>
          </div>
        </transition>

        <div class="oap-msm__vto-loader">
          <OapLoader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadscript } from '@Foundation/directives/loadscript/loadscript';
import { AnalyticsHandler, getCookie } from '@Foundation';
import OapLoader from '../../../../OapLoader/code/Scripts/components/OapLoader.vue';

const FRAME_NAME = 'oap-msm__vto-frame';

const PAGES = {
  PHOTO_DASHBOARD_VIEW: 'photo-dashboard-view',
};

export default {
  name: 'OapMenShadeMatcher',

  components: {
    OapLoader,
  },

  props: {
    firstQuestion: { type: String, required: true },
    introPage: { type: Object, required: true },
    dictionary: { type: Object, required: true },
    questions: { type: Object, required: true },
    answers: { type: Object, required: true },
    shouldSendConsentId: { type: Boolean, default: false },
    userEmail: { type: String, default: '' },
    totalSteps: { type: Number, default: 3 },
    vto: { type: Object, required: true },
    colorThatReplaces: { type: String, default: 'brown' },
    colorThatGetsReplaced: { type: String, default: 'redish' },
    analyticsType: { type: String, default: 'userActionEvent' },
    analyticsCategory: { type: String, default: 'diagnostic::men shade matcher' },
  },

  data() {
    return {
      hasStarted: false,
      formData: {},
      history: [],
      showPopIn: false,
      showTip: true,
      isReplaceColorSelected: false,
      vtoState: {
        transition: false,
        mode: false,
        ready: false,
        page: null,
        beforeAfterBarPosition: 50,
      },
    };
  },

  computed: {
    showVtoUI() {
      return (
        this.vtoState.mode &&
        [PAGES.PHOTO_DASHBOARD_VIEW, 'before-after-view'].includes(this.vtoState.page)
      );
    },

    getCurrentStep() {
      return this.history.length + 1;
    },

    getCurrentProgress() {
      return `${Math.round((this.getCurrentStep / this.totalSteps) * 100)}%`;
    },

    getCurrentQuestion() {
      return this.history.length
        ? this.questions[this.history[this.history.length - 1]]
        : this.questions[this.firstQuestion];
    },

    getCurrentAnswer() {
      return this.isFilled ? this.answers[this.formData[this.getCurrentQuestion.id]] : null;
    },

    getCurrentPopIn() {
      return this.getCurrentAnswer && this.getCurrentAnswer.popIn;
    },

    getSelectedProduct() {
      return this.getCurrentAnswer && this.getCurrentAnswer.product;
    },

    isFilled() {
      return !!this.formData[this.getCurrentQuestion.id];
    },
  },

  mounted() {
    window.initModiface = this.initModiface;
  },

  methods: {
    getDestinationUrl() {
      const answersParams = Object.values(this.formData).toString();
      const destinationPath = this.answers[this.formData[this.history[0]]].destinationURL;
      const location = `${window.location.origin + decodeURIComponent(destinationPath)}`;

      const destinationUrl = new URL(location);
      destinationUrl.searchParams.append('answers', encodeURIComponent(answersParams));
      return destinationUrl.href;
    },

    start() {
      this.hasStarted = true;
    },

    resetCurrentSelection() {
      delete this.formData[this.getCurrentQuestion.id];
    },

    back() {
      if (!this.vtoState.mode) {
        this.resetCurrentSelection();
      }

      // hook for getting back to intro page if user on 1st question
      if (this.history.length === 0) {
        this.hasStarted = false;
        return;
      }

      if (this.vtoState.mode) {
        this.closeVto();
        return setTimeout(() => {
          this.resetCurrentSelection();
        }, 650);
      }

      this.history.pop();
      this.resetCurrentSelection();
    },

    next(answerId) {
      answerId
        ? (this.isReplaceColorSelected = true)
        : this.triggerAnalytics(
            `select::${this.getCurrentQuestion.analyticsAction}`,
            `${this.getCurrentQuestion.title}::${this.getCurrentAnswer.label}`
          );

      // hook for showing / hiding the pop-in
      // if pop-in is shown - close it and process the answer
      if (this.getCurrentPopIn && this.showPopIn) {
        this.togglePopIn();
      } else if (this.getCurrentPopIn) {
        this.togglePopIn();
        // if pop-in is hidden - open it and don't process the answer
        return;
      }

      this.processAnswer(answerId);
    },

    processAnswer(answerId) {
      if (this.getCurrentAnswer.submit) {
        this.goToDestinationUrl();
      } else {
        this.scrollRefIntoView('navigation');

        if (answerId) {
          this.formData[this.getCurrentQuestion.id] = answerId;
        }

        // optional transition delay for vto context switching
        // Try RequestAnimationFrame
        setTimeout(
          () => {
            this.setQuestionId(this.getCurrentAnswer.nextQuestion);
          },
          this.vtoState.mode ? 600 : 0
        );

        if (this.vtoState.mode) {
          this.closeVto();
        }
      }
    },

    togglePopIn() {
      this.scrollRefIntoView('navigation');

      if (this.showPopIn) {
        this.isReplaceColorSelected
          ? this.triggerAnalytics(
              `select::${this.colorThatReplaces}`,
              `${this.colorThatGetsReplaced} popin`
            )
          : this.triggerAnalytics('select::close', `${this.colorThatGetsReplaced} popin`);
      }

      this.showPopIn = !this.showPopIn;
    },

    triggerAnalytics(action, label) {
      AnalyticsHandler.getAnalyticsHandler().push({
        type: this.analyticsType,
        category: this.analyticsCategory,
        action,
        label,
      });
    },

    goToDestinationUrl() {
      window.location.href = this.getDestinationUrl();
    },

    setQuestionId(id) {
      this.history.push(id);
    },

    scrollRefIntoView(ref, behavior = 'smooth') {
      setTimeout(() => {
        this.$refs[ref].scrollIntoView({ behavior });
      }, 0);
    },

    close() {
      window.history.back();
    },

    startVto() {
      this.loadVto();
      this.scrollRefIntoView('vto');
      this.transitionVto(true);
    },

    loadVto() {
      loadscript.beforeMount(null, {
        value: {
          name: 'modiface-script',
          url: this.vto.scriptUrl,
        },
      });
    },

    initModiface() {
      /* istanbul ignore next */
      let frameParams = this.getFrameParams();

      if (this.shouldSendConsentId) {
        const id = new URLSearchParams(getCookie('OptanonConsent')).get('consentId');
        frameParams.oneTrustConsentId = id;
        frameParams.oneTrustUserEmail = this.userEmail;
      }

      /* istanbul ignore next */
      window.MF_CHANNEL_PARENT.addFrame(frameParams);

      /* istanbul ignore next */
      window.MF_CHANNEL_PARENT.listen('page_change', ({ page }) => {
        const isPhotoDashboardViewPage = page === PAGES.PHOTO_DASHBOARD_VIEW;
        if (this.vtoState.page === 'error-popup-view' && isPhotoDashboardViewPage) {
          this.resetCurrentSelection();
          this.sendMFRemoveShade();
        }

        this.vtoState.page = page;

        if (isPhotoDashboardViewPage && this.isFilled && this.getSelectedProduct) {
          this.upcChange(this.getSelectedProduct);
          this.modeBeforeAfter();
        }
      });

      // todo : on hold - performance concerns to be discussed with modiface
      // window.MF_CHANNEL_PARENT.listen('before_after_slider_bar_position', ({sliderLeft}) => {
      //       this.vtoState.beforeAfterBarPosition = Number.parseInt(sliderLeft, 10)
      // });

      /* istanbul ignore next */
      window.MF_CHANNEL_PARENT.listen('frame_ready', () => {
        this.vtoState.ready = true;
        this.setMFDisplay();
      });

      /* istanbul ignore next */
      window.MF_CHANNEL_PARENT.listen('exit', () => {
        if (this.vtoState.page === 'landing-view') {
          this.closeVto();
        }
      });

      /* istanbul ignore next */
      window.MF_CHANNEL_PARENT.listen('event_tracking', ({ trackingType }) => {
        if (trackingType === 'uploadPhotoCta') {
          this.$refs['msm-app'].classList.add('vto-transition-instant');
        }
        if (trackingType === 'uploadPhotoCtaLoaded') {
          this.$refs['msm-app'].classList.remove('vto-transition-instant');
        }
      });
    },

    getFrameParams() {
      const params = {
        divId: 'oap-msm__vto-frame-container',
        frameName: FRAME_NAME,
        publisherId: this.vto.publisherId,
        category: this.vto.category,
        service: this.vto.service,
        upcList: this.vto.upcList,
        country: this.vto.country,
        language: this.vto.language,
        shareCaptureCount: this.vto.shareCaptureCount,
        uxWrapper: true,
        useStandardTaggingPlan: true,
      };

      if (this.vto.text) {
        params.text = this.vto.text;
      }

      return params;
    },

    closeTip() {
      this.showTip = false;
    },

    selectOption({ product }) {
      this.showTip = true;
      this.scrollRefIntoView('next-button');

      if (this.vtoState.mode) {
        this.upcChange(product);
      }

      if (this.vtoState.mode && this.vtoState.page === PAGES.PHOTO_DASHBOARD_VIEW) {
        this.modeBeforeAfter();
      }
    },

    selectOptionWithEnter(refID) {
      this.$refs[`label-${refID}`][0].click();
    },

    transitionVto(toggle = !this.vtoState.mode, transitionDelay = 1200) {
      this.vtoState.transition = true;

      setTimeout(() => {
        this.vtoState.mode = toggle;
        this.vtoState.transition = false;
      }, transitionDelay);
    },

    closeVto(transitionDelay = 1200) {
      this.transitionVto(false, transitionDelay);

      setTimeout(() => {
        if (this.vtoState.ready) {
          /* istanbul ignore next */
          window.MF_CHANNEL_PARENT.send(
            {
              event: 'exit',
              data: {},
            },
            FRAME_NAME
          );

          this.sendMFRemoveShade();
        }
      }, transitionDelay * 2);
    },

    sendMFRemoveShade() {
      /* istanbul ignore next */
      window.MF_CHANNEL_PARENT.send(
        {
          event: 'upc_change',
          data: {
            remove: true,
          },
        },
        FRAME_NAME
      );
    },

    setMFDisplay() {
      /* istanbul ignore next */
      window.MF_CHANNEL_PARENT.send(
        {
          event: 'modify_iframe_cta_display',
          data: {
            // adjust coordinates CTA
            adjustCta: {
              display: false,
            },
            // face beautifier effect CTA
            beautyFilterCta: {
              display: false,
            },
            // before/after compare CTA
            beforeAfterCta: {
              display: false,
            },
            // close button
            closeButton: {
              display: false,
            },
            // favorite CTA
            favoriteCta: {
              display: false,
            },
            // add to cart CTA
            addToCartCta: {
              display: false,
            },
            // add to wishlist CTA
            addToWishlistCta: {
              display: false,
            },
            // quad compare CTA
            quadCta: {
              display: false,
            },
            // share CTA
            shareCta: {
              display: false,
            },
            // tutorial steps (previous/next) CTA
            tutorialCta: {
              display: false,
            },
            // undo CTA
            undoCta: {
              display: false,
            },
            // zoom CTA
            zoomCta: {
              display: false,
            },
            // surpriseMe CTA
            surpriseMeCta: {
              display: false,
            },
            // two shade comparison CTA
            twoShadeComparisonCta: {
              display: false,
            },
          },
        },
        FRAME_NAME
      );
    },

    modeBeforeAfter() {
      /* istanbul ignore next */
      window.MF_CHANNEL_PARENT.send(
        {
          event: 'remote_activate_cta',
          data: {
            ctaType: 'cta_beforeafter',
            ctaState: true,
          },
        },
        FRAME_NAME
      );
    },

    upcChange(product) {
      /* istanbul ignore next */
      window.MF_CHANNEL_PARENT.send(
        {
          event: 'upc_change',
          data: {
            upc: product.ean,
            prodName: product.name,
            shadeName: product.shade,
          },
        },
        FRAME_NAME
      );
    },

    isLoaded({ target }) {
      target.classList.add('is-loaded');
    },
  },
};
</script>
