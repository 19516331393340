<template>
  <div class="commerceConnector">
    <div class="commerceConnector__layout">
      <button
        class="commerceConnector__close"
        :data-tab-close="$options.settings.COMMERCE_CONNECTOR_TAB_ID"
        data-tab-a11y-focus
      >
        <svg class="icon" role="img" aria-labelledby="closeCommerceConnector" aria-hidden="true">
          <title id="closeCommerceConnector">Close window</title>
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close">
            {{ closeIconText }}
          </use>
        </svg>
      </button>
      <form>
        <div class="commerceConnector__wrapper">
          <tabs>
            <tab
              v-for="(country, index) in formattedData"
              :key="`${index}-${country.countryCode}`"
              :name="country.countryCode || 'empty'"
            >
              <fieldset v-if="shouldShowFieldset(country)">
                <legend
                  class="commerceConnector__description"
                  :class="{ '-shopsNotAvailable': country.shopsUnavailable }"
                >
                  <template v-if="country.shopsUnavailable">
                    {{ country.defaultDescriptionTitle }}
                  </template>
                  <template v-else>
                    <strong class="commerceConnector__description-title">
                      {{ country.descriptionProduct }}
                    </strong>
                    <span class="commerceConnector__description-text">
                      {{ country.descriptionText }}
                    </span>
                  </template>
                </legend>
              </fieldset>
              <ul v-if="!country.shopsUnavailable" class="commerceConnector__shops">
                <li
                  v-for="shopData in country.shops.filter((e) => {
                    return qrCodeRetailer
                      ? qrCodeRetailer == e.title.toLowerCase().replace(/\s+/, '-')
                      : e;
                  })"
                  :key="shopData.id"
                  class="commerceConnector__item"
                  :class="{
                    '-unavailable': shopData.unavailable,
                    handleBuy__eRetailer: addClassToHandleBuyTagging,
                  }"
                >
                  <a
                    :href="shopData.link"
                    class="commerceConnector__radioLabel commerceConnector__tabIndex commerceConnector__link"
                    :class="{
                      'commerceConnector__link--has-button': showShowBuyButton(
                        country.stockStatusVisibility,
                        shopData.stockStatus
                      ),
                    }"
                    target="_blank"
                    @click="getTaggingInfo(shopData.taggingInfo)"
                  >
                    <img
                      v-if="shopData.logo"
                      class="commerceConnector__logo"
                      :src="shopData.logo"
                      alt=""
                    />

                    <button
                      v-if="showShowBuyButton(country.stockStatusVisibility, shopData.stockStatus)"
                      class="oap-button -primary"
                      :disabled="shopData.unavailable"
                      type="button"
                    >
                      <span class="oap-button__label"> {{ shopData.stockStatus }} </span>
                    </button>
                    <span v-else>
                      {{ shopData.title }}
                    </span>
                  </a>
                </li>
              </ul>
            </tab>
          </tabs>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { AnalyticsHandler } from '../../../../../../Foundation/Core/code/Scripts';
import eventBus from '@loreal/eventbus-js';
import { mixinParseURL } from '../../../../../../Foundation/Core/code/Scripts/mixins/parseURL/parseURL';

export default {
  name: 'CommerceConnector',
  api: {
    events: {
      ready: 'ready',
      toggle: 'toggle',
    },
  },
  mixins: [mixinParseURL],
  props: {
    initialData: { type: [Array, Object], required: true },
    closeIconText: { type: String, required: false, default: 'Close window' },
  },
  settings: {
    COMMERCE_CONNECTOR_TAB_ID: 'commerce-connector-tab',
    COMMERCE_CONNECTOR_URL_PARAM: 'buy',
    VTO_REDIRECT_URL_PARAM: 'vto-redirect',
  },
  data() {
    return {
      ccConnectorEnabled: false,
      addClassToHandleBuyTagging: false,
      qrCodeRetailer: false,
      filterData: '',
    };
  },
  computed: {
    formattedData() {
      if (Array.isArray(this.initialData)) {
        if (this.ccConnectorEnabled) {
          return [this.initialData[0]];
        }
        return this.initialData;
      }
      return [this.initialData];
    },
  },
  created() {
    /* istanbul ignore next */
    if (this.URLHasKey(this.$options.settings.COMMERCE_CONNECTOR_URL_PARAM)) {
      setTimeout(() => {
        eventBus.emit('tab:open:external', this.$options.settings.COMMERCE_CONNECTOR_TAB_ID);
      }, 0);
    }

    /* istanbul ignore next */
    eventBus.on(`commerce-connector:${this.$options.api.events.toggle}`, (open) => {
      setTimeout(() => {
        if (open) {
          eventBus.emit('tab:open:external', this.$options.settings.COMMERCE_CONNECTOR_TAB_ID);
        }
      }, 0);
    });
  },
  mounted() {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let paramsValue = params.utm_retailer;
    if (paramsValue) {
      this.qrCodeRetailer = paramsValue.toLowerCase().replace(/\s+/, '-');
    }
    this.ccConnectorEnabled =
      document.getElementById('ccConnectorEnabled')?.value.toLowerCase() === 'true';

    if (this.URLHasKey(this.$options.settings.VTO_REDIRECT_URL_PARAM)) {
      this.addClassToHandleBuyTagging = true;
    }
  },
  methods: {
    showShowBuyButton(stockStatusVisibility, stockStatus) {
      return stockStatusVisibility && stockStatus;
    },
    shouldShowFieldset(country) {
      return (
        country.defaultDescriptionTitle || country.descriptionProduct || country.descriptionText
      );
    },
    getTaggingInfo(taggingData) {
      const { eventAction, eventLabel, ecommerce, eventName, step, productInfo, storeName, price } =
        taggingData;
      const handBuyactive = document.querySelector('.commerceConnector__item');
      const handBuyClick = handBuyactive.classList.contains('handleBuy__eRetailer');
      const handBuypageURL = new URLSearchParams(window.location.search);
      const handBuylastURL = handBuypageURL.has('utm_medium');
      if (handBuyClick === true || handBuylastURL === true) {
        ecommerce.add.products[0].dimension49 = 'activated';
      }
      /* istanbul ignore next */
      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'addToCart',
        ecommerce,
        category: 'Ecommerce',
        action: eventAction,
        label: eventLabel,
        event_name: eventName,
        step,
        productInfo,
        storeName,
        price,
      });
    },
  },
};
</script>
