<template>
  <div ref="concernsBlock" class="oap-concerns">
    <div class="oap-concerns__title">{{ title }}</div>
    <div
      :class="[
        'oap-concerns__item-title',
        { 'oap-concerns__item-title--hidden': isTextInfoHidden },
      ]"
    >
      {{ animatedConcernTitle }}
    </div>
    <div class="oap-concerns__wrapper">
      <div class="oap-concerns__slider">
        <div v-for="(concern, index) in concerns" :key="index" class="oap-concerns__slider-item">
          <picture>
            <source :srcset="concern.image" />
            <img
              class="lazyload"
              src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              :data-src="concern.image"
              alt=""
            />
          </picture>
        </div>
      </div>
    </div>
    <div
      v-if="animatedConcernShortAnswer"
      :class="[
        'oap-concerns__item-short-answer',
        { 'oap-concerns__item-short-answer--hidden': isShortAnswerHidden },
      ]"
    >
      {{ animatedConcernShortAnswer }}
    </div>
    <div
      v-if="activeConcern.answer"
      :class="[
        'oap-concerns__item-answer',
        { 'oap-concerns__item-answer--hidden': isTextInfoHidden },
      ]"
    >
      {{ activeConcern.answer }}
    </div>
    <div class="oap-concerns__footer">
      <div class="oap-concerns__controls">
        <button :disabled="isPrevItemDisabled" aria-label="Previous" @click="prevItem">
          <svg class="icon">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron" />
          </svg>
        </button>
        <button :disabled="isNextItemDisabled" aria-label="Next" @click="nextItem">
          <svg class="icon">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron" />
          </svg>
        </button>
      </div>
      <a v-if="activeConcern.link" :href="activeConcern.link.href">{{
        activeConcern.link.label
      }}</a>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { AnalyticsHandler } from '@Foundation';

const props = defineProps({
  title: { type: String, default: '' },
  concerns: { type: Array, default: () => [] },
  swipeDistanceToSlide: { type: Number, default: 60 },
  analytics: { type: Object, default: () => {} },
});

const ROTATION_VALUES = [-78, -52, -26, 0, 26, 52, 78];
const TIMEOUT_DELAY = 50;
const NUMBER_OF_RANGE = 3;

const concernsBlock = ref(null);

let CONCERNS_SLIDER_ITEMS = null;
let dragDistance = 0;
let titleTimeout = null;

const activeItemIndex = ref(Math.floor((props.concerns.length - 1) / 2));
watch(activeItemIndex, (newValue) => {
  if (newValue || newValue === 0) {
    isTextInfoHidden.value = true;
    isShortAnswerHidden.value = true;
    concernTitleShouldBeTyped.value = false;
    concernShortAnswerShouldBeTyped.value = false;

    clearTimeout(titleTimeout);
    moveConcernImages(CONCERNS_SLIDER_ITEMS);
    pushAnalytics();
  }
});

const concernTitleShouldBeTyped = ref(false);
watch(concernTitleShouldBeTyped, (newValue) => {
  if (newValue) {
    typeTitle();
  }
});

const concernShortAnswerShouldBeTyped = ref(false);
watch(concernShortAnswerShouldBeTyped, (newValue) => {
  if (newValue && concernTitle.value.length === activeConcern.value.title.length) {
    typeShortAnswer();
  }
});

const isTextInfoHidden = ref(false);
const isShortAnswerHidden = ref(false);

const activeConcern = computed(() => {
  return props.concerns[activeItemIndex.value];
});

const concernTitle = ref(activeConcern.value.title);
const animatedConcernTitle = computed(() => {
  return concernTitle.value;
});

const concernShortAnswer = ref(activeConcern.value.shortAnswer);
const animatedConcernShortAnswer = computed(() => {
  return concernShortAnswer.value;
});

const isPrevItemDisabled = computed(() => {
  return activeItemIndex.value === 0;
});

const isNextItemDisabled = computed(() => {
  return activeItemIndex.value === props.concerns.length - 1;
});

function moveConcernImages(items) {
  items.forEach((item, index) => {
    const targetIndex = index - activeItemIndex.value;
    const rotationIndex = Math.min(
      Math.max(targetIndex + NUMBER_OF_RANGE, 0),
      ROTATION_VALUES.length - 1
    );

    item.style.transform = `translate(-50%, -50%) rotate(${ROTATION_VALUES[rotationIndex]}deg)`;

    if (targetIndex >= -NUMBER_OF_RANGE && targetIndex <= NUMBER_OF_RANGE) {
      const rotation = -ROTATION_VALUES[rotationIndex];
      item.children[0].style.transform = `translate(-50%, 0) rotate(${rotation}deg)`;

      if (
        Math.abs(ROTATION_VALUES[rotationIndex]) === ROTATION_VALUES[ROTATION_VALUES.length - 1]
      ) {
        item.children[0].style.opacity = '0';
      } else {
        item.children[0].style.opacity = '1';
        if (ROTATION_VALUES[rotationIndex] === ROTATION_VALUES[1]) {
          item.children[0].classList.add('-blur-start');
        } else if (ROTATION_VALUES[rotationIndex] === ROTATION_VALUES[ROTATION_VALUES.length - 2]) {
          item.children[0].classList.add('-blur-end');
        } else {
          item.children[0].classList.remove('-blur-start');
          item.children[0].classList.remove('-blur-end');
        }
      }
    } else {
      item.children[0].style.transform = `translate(-50%, 0) rotate(${-ROTATION_VALUES[
        ROTATION_VALUES.length - 1
      ]}deg)`;
    }
  });
}

function pushAnalytics() {
  if (props.analytics) {
    try {
      AnalyticsHandler.getAnalyticsHandler().push(props.analytics);
    } catch (e) {
      /* istanbul ignore next */
      console.warn('Could not push to dataLayer', e);
    }
  }
}

function prevItem() {
  if (activeItemIndex.value > 0) {
    activeItemIndex.value--;
  }
}

function nextItem() {
  if (activeItemIndex.value !== props.concerns.length - 1) {
    activeItemIndex.value++;
  }
}

function typeTitle() {
  concernTitle.value = '';

  [...activeConcern.value.title].forEach((item, index) => {
    titleTimeout = setTimeout(() => {
      concernTitle.value += item;

      if (concernTitle.value.length === activeConcern.value.title.length) {
        isShortAnswerHidden.value = false;
        concernShortAnswerShouldBeTyped.value = true;
      }
    }, TIMEOUT_DELAY * index);
  });
}

function typeShortAnswer() {
  concernShortAnswer.value = '';

  if (!activeConcern.value.shortAnswer) return;

  [...activeConcern.value.shortAnswer].forEach((item, index) => {
    setTimeout(() => {
      concernShortAnswer.value += item;

      if (concernShortAnswer.value.length === activeConcern.value.shortAnswer.length) {
        concernShortAnswerShouldBeTyped.value = false;
      }
    }, TIMEOUT_DELAY * index);
  });
}

function handleTouchMove(e, dragValue) {
  if (dragValue < 0 && Math.abs(dragValue) >= props.swipeDistanceToSlide) {
    prevItem();
  } else if (dragValue > 0 && Math.abs(dragValue) >= props.swipeDistanceToSlide) {
    nextItem();
  }
}

function handleTouchStart(e) {
  if (!e.touches) {
    e.preventDefault();
  }

  dragDistance = 0;
  dragDistance = e.touches ? e.touches[0].pageX : e.pageX;
}

function handleTouchEnd(e) {
  if (!e.touches) {
    e.preventDefault();
  }

  dragDistance = dragDistance - (e.changedTouches ? e.changedTouches[0].pageX : e.pageX);

  if (!dragDistance && !e.touches) {
    if (e.target.closest('button')) {
      e.target.closest('button').click();
    }
    if (e.target.closest('a')) {
      e.target.closest('a').click();
    }
  }

  handleTouchMove(e, dragDistance);
}

function bindEventsToConcernsItems() {
  CONCERNS_SLIDER_ITEMS.forEach((item) => {
    item.addEventListener('transitionend', (e) => {
      if (e.target.tagName.toLowerCase() === 'div') {
        concernTitleShouldBeTyped.value = true;
        isTextInfoHidden.value = false;
      }
    });
  });
}

onMounted(() => {
  CONCERNS_SLIDER_ITEMS = concernsBlock.value.querySelectorAll('.oap-concerns__slider-item');

  bindEventsToConcernsItems();

  moveConcernImages(CONCERNS_SLIDER_ITEMS);

  if ('ontouchstart' in window) {
    concernsBlock.value.addEventListener('touchstart', handleTouchStart);
    concernsBlock.value.addEventListener('touchend', handleTouchEnd);
  }
});
</script>
