<template>
  <modal class="oap-skindr-app-wrapper" :modalidentifier="id" :has-close-icon="false" is-in-zapper>
    <LoadScript v-if="loaded" :attributes="{ type: 'module' }" :src="createUrl('js')">
      <skindr-app v-bind="config"></skindr-app>
    </LoadScript>
  </modal>
</template>

<script>
import LoadScript from '../../../../../../Foundation/Core/code/Scripts/loadscript/loadscript.vue';
import modal from '../../../../../Modal/code/Scripts/components/modal.vue';
import { mixinParseURL } from '@Foundation/mixins/parseURL/parseURL';
import eventBus from '@loreal/eventbus-js';

export default {
  name: 'OapSkindrAppWrapper',

  components: {
    LoadScript,
    modal,
  },

  mixins: [mixinParseURL],

  props: {
    config: { type: Object, required: true },
    baseUrl: { type: String, default: 'https://skindr-api.loreal.io/skindr/' },
  },

  data() {
    return {
      loaded: false,
      id: 'oap-dsf-app-wrapper',
    };
  },

  settings: {
    serviceTriggerURLParamKey: 'dsf-app',
    startEventName: 'oap-dsf-app-wrapper',
  },

  mounted() {
    eventBus.on(`${this.$options.settings.startEventName}:start`, this.start);

    /* istanbul ignore next */
    if (this.URLHasKey(this.$options.settings.serviceTriggerURLParamKey)) {
      eventBus.on(`modal:closed`, (payload) => {
        if (payload === this.$options.name) {
          window.history.back();
        }
      });
      this.start(true);
    }
  },

  methods: {
    start(isFromUrlParam = false) {
      /* istanbul ignore else */
      if (!this.loaded) {
        this.loaded = true;
      }
      eventBus.emit(`modal:${this.id}`, {
        showModal: true,
        isFromUrlParam,
      });
    },

    createUrl(type) {
      return `${this.baseUrl}skindr.${type}?brand=${this.config.brand}&country=${this.config.country}&env=${this.config.env}`;
    },
  },
};
</script>
