<template>
  <div class="oap-beauty-control">
    <button
      v-tag:useractionevent="{
        label: previousLabel,
        category: 'my beauty profile',
        action: 'profile',
      }"
      :disabled="disablePrevious"
      :class="['oap-beauty-control__btn', { 'oap-beauty-control__btn--disabled': disablePrevious }]"
      @click="$emit('back')"
    >
      {{ previousLabel }}
    </button>
    <button
      v-tag:useractionevent="{ label: nextLabel, category: 'my beauty profile', action: 'profile' }"
      :disabled="disableNext"
      :class="['oap-beauty-control__btn', { 'oap-beauty-control__btn--disabled': disableNext }]"
      @click="$emit('next')"
    >
      {{ nextLabel }}
    </button>
  </div>
</template>
<script>
import { tag } from '@Foundation/analyticsHandler/directives/tag';

export default {
  name: 'OapBeautyControl',
  directives: {
    tag,
  },
  props: {
    disablePrevious: { type: Boolean, required: true },
    disableNext: { type: Boolean, required: true },
    previousLabel: { type: String, required: false, default: 'Previous' },
    nextLabel: { type: String, required: false, default: 'Next' },
  },
};
</script>
