<template>
  <div
    :class="[
      'oap-beauty-option-wrapper',
      { 'oap-beauty-option-wrapper--colors': question.theme === 'colors' },
    ]"
  >
    <div v-for="(option, i) in question.options" :key="i" :class="['oap-beauty-option']">
      <div
        :class="[
          'oap-beauty-option__item',
          { 'oap-beauty-option__item--selected': option.selected },
          { 'oap-beauty-option__item--disabled': !option.selected && limitOfOptionsSelected },
          { 'oap-beauty-option__item--standard': question.theme === 'standard' },
          { 'oap-beauty-option__item--colors': question.theme === 'colors' },
        ]"
      >
        <input
          :id="option.id"
          :type="type"
          :name="question.id"
          :checked="option.selected"
          :disabled="!option.selected && limitOfOptionsSelected"
          :aria-describedby="titleQuestionIds"
          @change.prevent="update(option.id)"
        />
        <label :for="option.id">
          <div
            v-if="question.theme === 'colors'"
            class="oap-beauty-option-color"
            :style="style(option)"
          ></div>
          <span>{{ option.title }}</span>
          <div class="checkmark"></div>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OapBeautyOption',
  props: {
    question: { type: Object, required: true },
    titleQuestionIds: { type: String, required: true },
  },
  computed: {
    type() {
      return this.question.type === 'multiselect' ? 'checkbox' : 'radio';
    },
    limitOfOptionsSelected() {
      const selectedOptions = this.question.options.filter((option) => option.selected);
      return this.question.type === 'multiselect' && this.question.maxAnswersRequired > 0
        ? selectedOptions.length >= this.question.maxAnswersRequired
        : false;
    },
  },
  methods: {
    update(id) {
      if (this.question.type === 'multiselect') {
        this.updateCheckbox(id);
        return;
      }
      this.updateRadio(id);
    },
    updateRadio(id) {
      this.question.options = this.question.options.map((option) => {
        option.selected = option.id === id;
        return option;
      });
      this.$emit('updated');
    },
    updateCheckbox(id) {
      this.updateValues(id, this.limitOfOptionsSelected);
    },
    updateValues(id, lock) {
      this.question.options = this.question.options.map((option) => {
        const currentOption = option.id === id;
        if (lock && currentOption) {
          option.selected = false;
          return option;
        }
        if (currentOption) {
          option.selected = !option.selected;
        }
        return option;
      });
      this.$emit('updated');
    },
    style(option) {
      return { background: option.isColor ? option.value : `url('${option.value}')` };
    },
  },
};
</script>
