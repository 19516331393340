import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

/**
 * Finds the selected options concatanated
 *
 * @param options {Array} - list of option objects
 * @return {string} options selected concatenated
 */
const optionSelectedTagging = (options) => {
  let optionsSelected = options.filter((option) => option.selected);
  let selectedForTagging = '';
  optionsSelected.map((option) => {
    selectedForTagging +=
      optionsSelected.indexOf(option) > 0 ? `|${option.title}` : `${option.title}`;
  });
  return selectedForTagging;
};

/**
 * Generates the tagging object for a given question
 *
 * @param question {Object} - question object
 * @param sectionTitle {string} - title of the section where the question if from
 * @return {Object} question tagging event
 */
const questionTagging = (question, sectionTitle) => {
  const optionsSelected = optionSelectedTagging(question.options);

  if (!optionsSelected) {
    return;
  }

  return {
    type: 'userActionEvent',
    ecommerce: undefined,
    category: 'my beauty profile',
    action: `${sectionTitle}:${stripHTMLTags(question.title)}`,
    label: optionsSelected,
  };
};

/**
 * Places all tagging events from a given section into a pill
 *
 * @param section {Object} - section with the list of questions
 * @return {Array} tagging events for all the questions in the section
 */
const questionTaggingList = (section) => {
  if (!section?.questions) {
    return [];
  }

  return section.questions.filter((question) => questionTagging(question, section.title));
};

/**
 * Pushes each tagging event object to the datalayer
 *
 * @param section {Object} - section with the list of questions
 */
const pushTaggingEvents = (section) => {
  const taggingEvents = questionTaggingList(section);

  taggingEvents.forEach((event) => {
    try {
      AnalyticsHandler.getAnalyticsHandler().push(event);
    } catch (e) {
      console.warn(e);
    }
  });
};

const stripHTMLTags = (text) => {
  if (!text) {
    return '';
  }

  let el = document.createElement('div');
  el.innerHTML = text;
  return (el.textContent || el.innerText || '').trim();
};

export default {
  pushTaggingEvents,
};
