<template>
  <div>
    <div v-user-interaction @interacted="userHasInteracted" />
    <LoadScript
      v-if="shouldLoadScript"
      :src="scriptUrl"
      name="bazaarvoice"
      @loaded="onScriptLoad"
    />
    <slot v-if="isScriptLoaded" />
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import LoadScript from '../../../../../Foundation/Core/code/Scripts/loadscript/loadscript.vue';
import { intersectionViewportObserver } from '../../../../../Foundation/Core/code/Scripts/utilities';
import { userInteraction } from '../../../../../Foundation/Core/code/Scripts/directives/userInteraction/userInteraction';

export default {
  name: 'BvWrapper',

  components: {
    LoadScript,
  },

  directives: {
    userInteraction,
  },

  props: {
    consultEvent: { type: Object, required: true },
    scriptUrl: { type: String, required: true, default: '' },
  },

  data() {
    return {
      shouldLoadScript: false,
      isScriptLoaded: false,
    };
  },

  watch: {
    shouldLoadScript: {
      handler() {
        this.triggerConsultEvent();
      },
    },
  },

  mounted() {
    eventBus.on('bvLoadedEventBus', () => {
      this.shouldLoadScript = true;
    });

    intersectionViewportObserver(this.$el, { checkIsVisible: false, threshold: [0.006] }).then(
      () => {
        this.shouldLoadScript = true;
      }
    );
  },

  methods: {
    onScriptLoad() {
      this.isScriptLoaded = true;
    },

    triggerConsultEvent() {
      let interval = false;
      interval = setInterval(() => {
        if (
          document.getElementsByClassName('bvReviews__wrapper') &&
          document.getElementsByClassName('bv-content-list bv-content-list-reviews').length
        ) {
          window.dataLayer.push(this.consultEvent);
          clearInterval(interval);
        }
      }, 2000);
    },

    userHasInteracted() {
      this.shouldLoadScript = true;
    },
  },
};
</script>
