import once from 'lodash.once';
import { TOAST_EVENT_NAME } from './settings';
import { AnalyticsHandler } from '../../../../Foundation/Core/code/Scripts';
import eventBus from '@loreal/eventbus-js';

const actions = {
  emitToastNotification({ state }, { message, type }) {
    const timeout = state.toastTimeout;
    eventBus.$emit(TOAST_EVENT_NAME, { message, timeout, type });
  },

  fetchInitialFavoritedItems: once(async ({ commit, state }) => {
    const endpoint = state.apiEndpoints.all;

    await fetch(endpoint)
      .then((response) => response.json())
      .then((data) => {
        if (!data.Success) {
          if (data.StatusCode == 401) {
            commit('setFavoritedItems', []);
            return;
          }

          throw new Error(data.Message);
        }

        commit('setFavoritedItems', data.Data);
      })
      .catch((error) => {
        commit('setFavoritedItems', []);
        console.error('Could not fetch favorites', error);
      })
      .finally(() => {
        commit('setShowButton', true);
      });
  }),

  addFavorite: async ({ commit, dispatch, state }, { item, returnPage, taggingData }) => {
    const endpoint = Array.isArray(item) ? state.apiEndpoints.addList : state.apiEndpoints.add;

    await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      credentials: 'same-origin',
      body: JSON.stringify({ item }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.Success) {
          if (data.StatusCode == 401) {
            dispatch('redirectToSignIn', { returnPage });
            return;
          }

          throw new Error(data.Message);
        }

        commit('addFavoriteItem', item);
        dispatch('emitToastNotification', {
          message: data.Message,
          type: 'success',
        });

        dispatch('sendTagEvent', { action: 'add', taggingData });
      })
      .catch((error) => {
        dispatch('emitToastNotification', {
          message: error,
          type: 'error',
        });
        console.error('Could not add favorite', error);
      });
  },

  redirectToSignIn: ({ state }, { returnPage }) => {
    const destination = returnPage || window.location.pathname;

    window.location.href = `${state.loginUrl}?favorite=true&returnPage=${destination}`;
  },

  removeFavorite: async ({ commit, dispatch, state }, { item, returnPage, taggingData }) => {
    const endpoint = state.apiEndpoints.remove;

    await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      credentials: 'same-origin',
      body: JSON.stringify({ item }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.Success) {
          if (data.StatusCode == 401) {
            dispatch('redirectToSignIn', { returnPage });
            return;
          }

          throw new Error(data.Message);
        }

        commit('removeFavoriteItem', item);
        dispatch('emitToastNotification', {
          message: data.Message,
          type: 'success',
        });

        dispatch('sendTagEvent', { action: 'remove', taggingData });
      })
      .catch((error) => {
        dispatch('emitToastNotification', {
          message: error,
          type: 'error',
        });
        console.error('Could not remove favorite', error);
      });
  },

  sendTagEvent: (_, { action, taggingData }) => {
    if (!taggingData) return;

    const { actions, category, label } = taggingData;

    AnalyticsHandler.getAnalyticsHandler().push({
      action: actions[action],
      category,
      label,
      type: 'userActionEvent',
    });
  },

  setupStore: ({ commit }, payload) => {
    const { apiEndpoints, loginUrl, toastTimeout } = payload;

    commit('setApiEndpoints', apiEndpoints);
    commit('setLoginUrl', loginUrl);
    commit('setToastTimeout', toastTimeout);
  },
};

const getters = {
  favoritedItems: (state) => state.favoritedItems,
  showButton: (state) => state.showButton,
};

const mutations = {
  addFavoriteItem: (state, payload) => {
    if (Array.isArray(payload)) {
      payload.forEach((item) => state.favoritedItems.push(item));
      return;
    }

    state.favoritedItems.push(payload);
  },
  removeFavoriteItem: (state, payload) => {
    state.favoritedItems = state.favoritedItems.filter((item) => {
      const hasRemovedId = item.ItemId.toLowerCase() === payload.ItemId.toLowerCase();
      const hasRemovedType = item.Type === payload.Type;

      return !hasRemovedId ? true : !hasRemovedType;
    });
  },
  setApiEndpoints: (state, payload) => {
    if (state.apiEndpoints) return;
    state.apiEndpoints = payload;
  },
  setFavoritedItems: (state, payload) => {
    state.favoritedItems = payload;
  },
  setLoginUrl: (state, payload) => {
    if (state.loginUrl) return;
    state.loginUrl = payload;
  },
  setToastTimeout: (state, payload) => {
    if (state.toastTimeout) return;
    state.toastTimeout = payload;
  },
  setShowButton: (state, payload) => {
    state.showButton = payload;
  },
};

const state = {
  apiEndpoints: null,
  favoritedItems: null,
  loginUrl: null,
  showButton: false,
  toastTimeout: null,
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
