<template>
  <div class="rfp-iframe-177">
    <iframe
      :id="datas.IFrameId"
      :class="dynamicClass"
      v-bind:style="boolVariable ? '' : 'display: none;'"
      title="iframe widget"
    ></iframe>
    <rfp-iframe-loader
      :text="datas.LinkText"
      :url="datas.Url"
      :data-layers="datas.ShowIFrameTags"
      :frame="datas.IFrameId"
      :type="div"
      class="rfp-iframe-loader-200 rfp-iframe-loader-static"
    ></rfp-iframe-loader>
  </div>
</template>
<script>
export default {
  name: 'rfp-iframe-main-container',
  props: {
    datas: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dynamicClass: `rfp-iframe_dynamic_${this.datas.IFrameId}`,
      boolVariable: false,
    };
  },
  methods: {},
  created() {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `.rfp-iframe_dynamic_${this.datas.IFrameId} {
        border: none;
        width:${this.datas.Width};
        height:${this.datas.Height}
      }
      .rfp-iframe-loader-static {
        cursor: pointer;
        color: blue;
        text-decoration: underline;
      }
      @media screen and (max-width:767px){
          .rfp-iframe_dynamic_${this.datas.IFrameId}{
            width: ${this.datas.WidthMobile};
            height: ${this.datas.HeightMobile};
            min-width:100vw;
          }
      }`;
    document.getElementsByTagName('head')[0].appendChild(style);
    window.eventBus.on(this.datas.IFrameId, () => {
      this.boolVariable = true;
    });
  },
};
</script>
