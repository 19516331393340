<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <LoadScript
    v-if="showDsfAppWrapper"
    :attributes="{ type: 'module' }"
    src="https://dsf-cdn.loreal.io/dropper.js"
    @loaded="onDropperLoad"
  >
    <dsf-app :switch_ecom="turnOnEcomCta" v-bind="config"></dsf-app>
  </LoadScript>
</template>

<script>
import LoadScript from '../../../../../../Foundation/Core/code/Scripts/loadscript/loadscript.vue';
import { mixinParseURL } from '../../../../../../Foundation/Core/code/Scripts/mixins/parseURL/parseURL';

export default {
  name: 'OapDsfAppWrapper',

  components: {
    LoadScript,
  },

  mixins: [mixinParseURL],

  props: {
    config: { type: Object, required: true },
  },

  data() {
    return {
      showDsfAppWrapper: false,
      turnOnEcomCta: true,
    };
  },

  settings: {
    serviceButtonClass: '.button-open-dsf-app',
    serviceTriggerURLParamKey: 'dsf-app',
  },

  created() {
    window.addEventListener('load', () => {
      this.showDsfAppWrapper = true;
    });
  },
  mounted() {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let paramsValue = params.utm_source == 'pos';
    if (paramsValue) {
      this.turnOnEcomCta = false;
    }
  },
  methods: {
    onDropperLoad() {
      window.dsfApp?.addEventListener('onLoadComplete', this.onLoadComplete);
    },

    onLoadComplete() {
      const clickServiceButton = document.body.querySelector(
        this.$options.settings.serviceButtonClass
      );

      if (this.URLHasKey(this.$options.settings.serviceTriggerURLParamKey)) {
        clickServiceButton.click();
      }
    },
  },
};
</script>
