<template>
  <div class="oap-flowplayer">
    <div id="player"></div>
    <button
      v-if="control"
      :class="['oap-flowplayer__control', `oap-flowplayer__control--is-${controlPosition}`]"
      @click="togglePlaying"
    >
      <span class="oap-flowplayer__control-overlay">
        <img :src="playButtonSrc" :alt="playButtonTitle" />
      </span>
    </button>
  </div>
</template>

<script>
import flowplayer from '@flowplayer/player';
import { FLOWPLAYER_STYLE_URL } from './settings';

const LARGE_SCREEN_START = 999;

export default {
  name: 'OapFlowplayer',

  props: {
    token: { type: String, default: '' },
    sources: { type: Object, default: null },
    src: { type: String, default: '' },
    poster: { type: Object, default: null },
    ratio: { type: String, default: '' },
    autoplay: { type: Boolean, default: false },
    muted: { type: Boolean, default: true },
    loop: { type: Boolean, default: true },
    control: { type: Boolean, default: false },
    controlPosition: { type: String, default: 'bottom-left' },
    playButtonTitle: { type: String, default: 'play or pause video' },
  },

  data() {
    return {
      playing: this.autoplay,
    };
  },

  computed: {
    isLargeScreen() {
      return window.innerWidth > LARGE_SCREEN_START;
    },

    videoSrc() {
      return this.isLargeScreen ? this.sources.lg : this.sources.sm;
    },

    posterSrc() {
      return this.isLargeScreen ? this.poster.lg : this.poster.sm;
    },

    playButtonSrc() {
      return this.playing
        ? '/frontend/static/images/media-gallery-pause.svg'
        : '/frontend/static/images/media-gallery-play.svg';
    },
  },

  watch: {
    playing(newVal) {
      newVal ? this.player.play() : this.player.pause();
    },
  },

  mounted() {
    this.player = flowplayer('#player', {
      token: this.token,
      src: this.videoSrc,
      poster: this.posterSrc,
      ratio: this.ratio,
      autoplay: this.autoplay,
      muted: this.muted,
      loop: this.loop,
    });
  },

  destroyed() {
    this.player.destroy();
  },

  created() {
    // Add the flowplayer stylesheet
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = FLOWPLAYER_STYLE_URL;
    document.head.appendChild(link);
  },

  methods: {
    togglePlaying() {
      this.playing = !this.playing;
    },
  },
};
</script>
