import OapMakeTheSwitch from './code/Scripts/components/oap-make-the-switch.vue';
import OapMakeTheSwitchInput from './code/Scripts/components/oap-make-the-switch-input.vue';
import OapMakeTheSwitchQuiz from './code/Scripts/components/oap-make-the-switch-quiz.vue';
import OapMakeTheSwitchResultProduct from './code/Scripts/components/oap-make-the-switch-result-product.vue';
import OapMakeTheSwitchResultRecommendations from './code/Scripts/components/oap-make-the-switch-result-recommendations.vue';
import OapMakeTheSwitchTopNav from './code/Scripts/components/oap-make-the-switch-top-nav.vue';

import oapMakeTheSwitchStore from './code/Scripts/store';

export default {
  components: {
    OapMakeTheSwitch,
    OapMakeTheSwitchInput,
    OapMakeTheSwitchQuiz,
    OapMakeTheSwitchResultProduct,
    OapMakeTheSwitchResultRecommendations,
    OapMakeTheSwitchTopNav,
  },

  modules: {
    oapMakeTheSwitchStore,
  },
};
