<template>
  <button
    v-if="show"
    ref="floatingIsland"
    class="oap-button -secondary oap-beauty-genius-floating-island"
    @click="openTypeInField"
  >
    <img class="oap-beauty-genius-floating-island__image" :src="logoUrl" :alt="altText" />
    <span class="oap-beauty-genius-floating-island__cta">
      <span class="oap-beauty-genius-floating-island__label">{{ ctaLabel }}</span>
      <svg class="oap-beauty-genius-floating-island__icon" role="img">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="svgIconId"></use>
      </svg>
    </span>
  </button>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '@Foundation';
import { scrollVisibilityTracker } from '@Foundation/directives/scrollVisibilityTracker/scrollVisibilityTracker';

const props = defineProps({
  altText: { type: String, default: 'Beauty Genius' },
  classToShow: { type: String, default: 'is-flex' },
  ctaLabel: { type: String, default: 'Ask me anything' },
  logoUrl: {
    type: String,
    default: '/frontend/static/images/beauty-genius-monogram-logo--gold.svg',
  },
  scrollDepthPercentage: { type: Number, default: 75 },
  svgIconId: { type: String, default: '#arrow-top-right' },

  iframeOpenEventName: { type: String, default: 'beauty-genius-iframe:open' },
  iframeCloseEventName: { type: String, default: 'beauty-genius-iframe:close' },

  modalOpenEventName: { type: String, default: 'beauty-genius-modal:open' },
  modalCloseEventName: { type: String, default: 'beauty-genius-modal:close' },

  analytics: { type: Object, default: () => {} },
});

const beenOpenedWithDirective = ref(false);

onMounted(() => {
  listenToOpenEvents();
  attachDirective();

  eventBus.on('scroll-tracking:reached', () => {
    beenOpenedWithDirective.value = true;
    showIsland();
    prepareAndPushAnalytics();
  });
});

function prepareAndPushAnalytics() {
  if (props.analytics.noninteractiveevent) {
    const analytics = {
      type: 'nonInteractiveEvent',
      ...props.analytics.noninteractiveevent,
    };

    pushAnalytics(analytics);
  }
}

const floatingIsland = ref(null);

function attachDirective() {
  scrollVisibilityTracker.beforeMount(floatingIsland.value, {
    value: {
      scrollDepthPercentage: props.scrollDepthPercentage,
      classToShow: props.classToShow,
    },
  });
}

function openTypeInField() {
  let event = {};

  if (props.analytics?.useractionevent) {
    event.payload = {
      event_name: props.analytics.useractionevent.event_name,
      action: props.analytics.useractionevent.action,
    };
  }

  eventBus.emit(props.modalOpenEventName, event);
}

function listenToOpenEvents() {
  eventBus.on(props.iframeOpenEventName, hideIsland);
  eventBus.on(props.modalOpenEventName, hideIsland);
  eventBus.on(props.iframeCloseEventName, showIsland);
  eventBus.on(props.modalCloseEventName, showIsland);
}

const show = ref(true);

function showIsland() {
  if (beenOpenedWithDirective.value) {
    show.value = true;
  }
}

function hideIsland() {
  show.value = false;
}

function pushAnalytics(analytics) {
  try {
    AnalyticsHandler.getAnalyticsHandler().push(analytics);
  } catch (e) {
    /* istanbul ignore next */
    console.warn('Could not push to dataLayer', e);
  }
}
</script>
