<template>
  <div :id="navigationId" ref="ingredients" class="oap-product-ingredients">
    <h2 class="oap-product-ingredients__header">{{ header }}</h2>
    <div
      :class="[
        'oap-product-ingredients__content',
        `oap-product-ingredients__content--layout-${layout}`,
      ]"
    >
      <video
        v-if="items[activeItemIndex].video"
        class="oap-product-ingredients__video"
        preload="metadata"
        :src="videoSource"
        :autoplay="items[activeItemIndex].video.autoplay"
        playsinline
        muted
        loop
        aria-hidden="true"
      ></video>
      <picture v-if="items[activeItemIndex].image" class="oap-product-ingredients__image">
        <source media="(min-width: 650px)" :srcset="imageSource.lg" />
        <source :srcset="imageSource.sm" />
        <img
          class="lazyload"
          alt=""
          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          :data-src="imageSource.sm"
          aria-hidden="true"
        />
      </picture>
      <div ref="innerBlock" class="oap-product-ingredients__inner">
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="[
            'oap-product-ingredients__card',
            { 'oap-product-ingredients__card--active': activeItemIndex === index },
            { 'oap-product-ingredients__card--open': activeItemIndex === index && isCardExpanded },
          ]"
        >
          <div class="oap-product-ingredients__subtitle">
            {{ item.subtitle }}
            <button @click="expandCard(!isCardExpanded)">
              <svg class="icon">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron" />
              </svg>
            </button>
          </div>
          <h3 class="oap-product-ingredients__title">{{ item.title }}</h3>
          <div class="oap-product-ingredients__collapsible">
            <p class="oap-product-ingredients__summary">
              {{ item.summary }}
            </p>
            <a
              class="oap-product-ingredients__link"
              :href="item.link.href"
              :aria-label="`Discover this ingredient: ${item.title}`"
            >
              {{ item.link.label }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="oap-product-ingredients__footer">
      <div v-if="items.length > 1" class="oap-product-ingredients__controls">
        <button :disabled="isPrevDisabled" aria-label="Previous" @click="prevItem">
          <svg class="icon">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron" />
          </svg>
        </button>
        <button :disabled="isNextDisabled" aria-label="Next ingredient" @click="nextItem">
          <svg class="icon">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron" />
          </svg>
        </button>
      </div>
      <a :href="linkForAll.href">{{ linkForAll.label }}</a>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { AnalyticsHandler, intersectionViewportObserver } from '@Foundation';

const INNER_INDENT = {
  sm: 40,
  lg: 32,
};

const INGREDIENTS_CARD_SELECTOR = '.oap-product-ingredients__card';

const MOBILE_SCREEN_END = 650;

const props = defineProps({
  header: { type: String, default: 'Ingredients' },
  items: { type: Array, default: () => [] },
  linkForAll: { type: Object, default: () => {} },
  swipeDistanceToSlide: { type: Number, default: 60 },
  analytics: { type: Object, default: () => {} },
  navigationId: { type: String, default: '' },
});

const isMobile = computed(() => {
  return window.innerWidth < MOBILE_SCREEN_END;
});

const isPrevDisabled = computed(() => {
  return activeItemIndex.value === 0;
});

const isNextDisabled = computed(() => {
  return activeItemIndex.value === props.items.length - 1;
});

const imageSource = computed(() => {
  return props.items[activeItemIndex.value].image;
});

const videoSource = computed(() => {
  if (!isInViewport.value) return;

  return isMobile.value
    ? props.items[activeItemIndex.value].video.src.sm
    : props.items[activeItemIndex.value].video.src.lg;
});

const layout = computed(() => {
  switch (props.items.length) {
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    default:
      return 'default';
  }
});

const activeItemIndex = ref(0);
const isCardExpanded = ref(false);

let cards = null;

const innerBlock = ref(null);
const ingredients = ref(null);

let isInViewport = ref(false);

let dragDistance = 0;

function pushAnalytics() {
  if (props.analytics) {
    try {
      AnalyticsHandler.getAnalyticsHandler().push(props.analytics);
    } catch (e) {
      /* istanbul ignore next */
      console.warn('Could not push to dataLayer', e);
    }
  }
}

function prevItem() {
  if (activeItemIndex.value > 0) {
    activeItemIndex.value--;

    moveItem(activeItemIndex.value);
  }

  pushAnalytics();
}

function nextItem() {
  if (activeItemIndex.value !== props.items.length - 1) {
    activeItemIndex.value++;

    moveItem(activeItemIndex.value);
  }

  pushAnalytics();
}

function moveItem(index) {
  expandCard(false);

  if (isMobile.value) {
    expandCard(true);

    innerBlock.value.scrollTo({
      left: cards[index].offsetLeft - INNER_INDENT.sm,
      behavior: 'smooth',
    });
  } else {
    innerBlock.value.scrollTo({
      top:
        cards[index].offsetTop - cards[index - 1]?.getBoundingClientRect().height - INNER_INDENT.lg,
      behavior: 'smooth',
    });
  }
}

function expandCard(value) {
  isCardExpanded.value = value;
}

function handleTouchStart(e) {
  e.preventDefault();

  dragDistance = 0;

  if (isMobile.value) {
    dragDistance = e.touches ? e.touches[0].pageX : e.pageX;
  } else {
    dragDistance = e.touches ? e.touches[0].pageY : e.pageY;
  }
}

function handleTouchEnd(e) {
  e.preventDefault();

  if (isMobile.value) {
    dragDistance = dragDistance - (e.changedTouches ? e.changedTouches[0].pageX : e.pageX);
  } else {
    dragDistance = dragDistance - (e.changedTouches ? e.changedTouches[0].pageY : e.pageY);
  }

  if (!dragDistance) {
    if (e.target.closest('button')) {
      e.target.closest('button').click();
    }
    if (e.target.closest('a')) {
      e.target.closest('a').click();
    }
  }

  handleTouchMove(e, dragDistance);
}

function handleTouchMove(e, dragValue) {
  if (dragValue < 0 && Math.abs(dragValue) >= props.swipeDistanceToSlide) {
    prevItem();
  } else if (dragValue > 0 && Math.abs(dragValue) >= props.swipeDistanceToSlide) {
    nextItem();
  }
}

onMounted(() => {
  cards = innerBlock.value.querySelectorAll(INGREDIENTS_CARD_SELECTOR);

  if (isMobile.value && activeItemIndex.value === 0) {
    isCardExpanded.value = true;
  }

  intersectionViewportObserver(ingredients.value, {
    checkIsVisible: false,
    threshold: [0.006],
  }).then(() => {
    isInViewport.value = true;
  });

  if ('ontouchstart' in window) {
    innerBlock.value.addEventListener('touchstart', handleTouchStart);
    innerBlock.value.addEventListener('touchend', handleTouchEnd);
  }
});
</script>
