<template>
  <transition name="modal">
    <div v-if="showPopin" :class="['oap-video-popin videoComponent', { '-landscape': landscape }]">
      <modal
        :modalidentifier="modalId"
        :class="[
          'videoComponent__modal',
          { 'videoComponent__modal--has-transcript': hasTranscript },
        ]"
      >
        <div id="player"></div>
        <slot name="transcript"></slot>
      </modal>
    </div>
  </transition>
</template>

<script setup>
import { nextTick, ref, onMounted } from 'vue';
import eventBus from '@loreal/eventbus-js';
import flowplayer from '@flowplayer/player';
import modal from '@Feature/Modal/code/Scripts/components/modal.vue';
import { FLOWPLAYER_STYLE_URL } from '@Feature/OapFlowplayer/code/Scripts/components/settings';
import { HERO_VIDEO_BACKGROUND_PLAYING } from '@Feature/Filter/code/Scripts/constants/eventNames';

const props = defineProps({
  token: { type: String, default: '' },
  src: { type: String, default: '' },
  poster: { type: String, default: '' },
  ratio: { type: String, default: '' },
  autoplay: { type: Boolean, default: false },
  muted: { type: Boolean, default: true },
  loop: { type: Boolean, default: true },
  controls: { type: Boolean, default: true },
  modalId: { type: String, default: 'flowplayer-popin-id' },
  hasTranscript: { type: Boolean, default: false },
});

setFlowplayerStyles();

const landscape = ref(false);
const showPopin = ref(false);
let player = null;

onMounted(() => {
  eventBus.on('flowplayer:popin:show', (event) => {
    showPopin.value = true;

    landscape.value = event.payload.landscape;

    nextTick(() => {
      initFlowplayer(event.payload);

      eventBus.emit(`modal:${props.modalId}`);
    });

    eventBus.emit('livestream::pause');
    eventBus.emit(HERO_VIDEO_BACKGROUND_PLAYING, false);
  });

  eventBus.on('modal:closed', (modalidentifier) => {
    if (modalidentifier === props.modalId) {
      closeModal();
    }
  });
});

function closeModal() {
  showPopin.value = false;
  player.destroy();
  eventBus.emit('livestream::play');
  eventBus.emit(HERO_VIDEO_BACKGROUND_PLAYING, true);
}

function initFlowplayer(options) {
  player = flowplayer('#player', {
    token: options.token ? options.token : props.token,
    src: options.src ? options.src : props.src,
    poster: options.poster ? options.poster : props.poster,
    ratio: options.ratio ? options.ratio : props.ratio,
    autoplay: typeof options.muted == 'boolean' ? options.autoplay : props.autoplay,
    muted: typeof options.muted == 'boolean' ? options.muted : props.muted,
    loop: options.loop ? options.loop : props.loop,
    controls: options.controls ? options.controls : props.controls,
  });
}

function setFlowplayerStyles() {
  // Add the flowplayer stylesheet
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = FLOWPLAYER_STYLE_URL;
  document.head.appendChild(link);
}
</script>
