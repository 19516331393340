window.addEventListener(
  'load',
  function () {
    const buyNowMctbTriggers = document.querySelectorAll('#buyNowMctb, #buyNowMctb_01');
    const buyNowBtn = document.querySelector('.rfp-mctb-common-buynow');
    const buyNowGetQueryString = new URLSearchParams(window.location.search);
    const buyNowQueryStringKey = buyNowGetQueryString.has('buy');
    if (buyNowQueryStringKey === true && buyNowBtn) {
      buyNowBtn.click();
    }
    if (buyNowMctbTriggers.length) {
      buyNowMctbTriggers.forEach((buyNowMctb) => {
        buyNowMctb.addEventListener('click', () => {
          if (buyNowBtn !== null) {
            buyNowBtn.click();
          }
        });
      });
    }
    const matchMedia400 = window.matchMedia('(max-width:400px)').matches;
    let ctBuy = document.querySelector('._rfp_mctb_popup_02_');
    if (matchMedia400 && ctBuy) {
      ctBuy.addEventListener('click', function () {
        document.documentElement.style.height = `100vh`;
        setTimeout(() => {
          let closeCtBuy = document.querySelector('.rfp-mctb-commerceConnector__close');
          if (closeCtBuy) {
            closeCtBuy.addEventListener('click', function () {
              return (document.documentElement.style.height = ``);
            });
          }
        }, 300);
      });
    }
  },
  false
);
