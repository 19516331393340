<template>
  <div class="oap-make-the-switch-input">
    <template v-if="isSelect">
      <label :for="`select-${questionId}`" class="oap-make-the-switch-select">
        <select :id="`select-${questionId}`" v-model="answer" @input="emitInputUpdate">
          <option v-for="{ id, label } in treatedOptions" :key="id" :disabled="!id" :value="id">
            {{ label }}
          </option>
        </select>
        <div class="oap-make-the-switch-select__arrow"></div>
      </label>
    </template>

    <template v-else>
      <label
        v-for="{ id, label } in treatedOptions"
        :key="id"
        class="oap-make-the-switch-radio"
        :class="{ 'oap-make-the-switch-radio--checked': isChecked(id) }"
        :for="id"
      >
        <input
          :id="id"
          v-model="answer"
          :name="group"
          type="radio"
          :value="id"
          @input="emitInputUpdate"
        />
        {{ label }}
      </label>
    </template>
  </div>
</template>

<script>
export default {
  name: 'OapMakeTheSwitchInput',

  props: {
    isSelect: {
      default: false,
      type: Boolean,
    },
    options: {
      required: true,
      type: Array,
    },
    placeholder: {
      default: null,
      type: String,
    },
    questionId: {
      required: true,
      type: String,
    },
  },

  data: () => ({
    answer: '',
  }),

  computed: {
    group() {
      return this.questionId;
    },

    treatedOptions() {
      const { isSelect, options, placeholder } = this;
      const hasPlaceholder = options.some((option) => option.id === '');

      // Add placeholder option
      if (isSelect && placeholder && !hasPlaceholder) {
        options.unshift({ id: '', label: placeholder });
      }

      return options;
    },
  },

  watch: {
    questionId() {
      this.answer = '';
    },
  },

  methods: {
    emitInputUpdate(event) {
      this.$emit('inputUpdate', event.target.value);
    },

    isChecked(value) {
      return this.answer === value;
    },
  },
};
</script>
