<template>
  <div class="oap-make-the-switch-quiz">
    <form class="oap-make-the-switch-quiz__wrapper" method="POST">
      <div class="quiz-modal">
        <fieldset class="quiz-modal__content">
          <legend class="quiz-modal__legend">
            <small class="quiz-modal__content-steps-counter" v-text="stepText" />
            <component
              :is="currentQuestion.title.heading"
              class="quiz-modal__content-title"
              v-text="currentQuestionTitle"
            />
          </legend>

          <oap-make-the-switch-input
            :is-select="currentQuestion.isSelect"
            :options="currentOptions"
            :placeholder="currentQuestion.placeholder"
            :question-id="currentQuestion.id"
            @inputUpdate="updateCurrentAnswer"
          />

          <p class="quiz-modal__error" role="alert" v-text="errorMessage" />
        </fieldset>

        <div class="quiz-modal__footer">
          <p v-if="disclaimer" class="quiz-modal__footer-disclaimer" v-text="disclaimer" />
          <button
            v-tag:useractionevent.dom
            class="quiz-modal__footer-button"
            :class="{ '-wait': processingAnswer }"
            :data-tag-action="treatedTag.action"
            :data-tag-category="treatedTag.category"
            :data-tag-label="treatedTag.label"
            data-tag-wrapper
            :disabled="!nextButtonAvailable"
            type="button"
            @click="getNextQuestion"
            v-text="currentQuestion.nextButtonLabel"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import oapMakeTheSwitchInput from './oap-make-the-switch-input.vue';

export default {
  name: 'OapMakeTheSwitchQuiz',

  components: {
    oapMakeTheSwitchInput,
  },

  props: {
    disclaimer: {
      default: '',
      type: String,
    },
    endpoint: {
      required: true,
      type: String,
    },
    firstOptions: {
      required: true,
      type: Array,
    },
    questions: {
      required: true,
      type: Array,
    },
    stepTextTemplate: {
      default: '',
      type: String,
    },
    tag: {
      required: true,
      type: Object,
    },
  },

  computed: {
    ...mapGetters('oapMakeTheSwitchStore', [
      'answersHistory',
      'currentAnswer',
      'currentOptions',
      'currentQuestion',
      'currentStep',
      'errorMessage',
      'processingAnswer',
    ]),

    currentQuestionTitle() {
      const raw = this.currentQuestion.title.label;

      if (!raw.includes('${a}')) {
        return raw;
      }

      const { answersHistory } = this;
      const lastAnswer = answersHistory[answersHistory.length - 1].answer.label;

      return raw.replace('${a}', lastAnswer);
    },

    nextButtonAvailable() {
      return !!this.currentAnswer && !this.processingAnswer;
    },

    stepText() {
      if (!this.stepTextTemplate) return;

      const a = this.currentStep;
      const b = this.questions.length;

      return this.stepTextTemplate.replace('${a}', a).replace('${b}', b);
    },

    treatedTag() {
      const action = this.tag.action.replace('${a}', this.currentStep - 1);

      return {
        action,
        category: this.tag.category,
        label: this.currentAnswer?.label,
      };
    },
  },

  watch: {
    currentStep() {
      this.$nextTick(() => {
        this.$el.querySelector('input, select')?.focus();
      });
    },
  },

  beforeMount() {
    this.setupStore({
      currentOptions: this.firstOptions,
      endpoint: this.endpoint,
      questions: this.questions,
    });
  },

  methods: {
    ...mapActions('oapMakeTheSwitchStore', [
      'getNextQuestion',
      'setupStore',
      'updateCurrentAnswer',
      'updateDisplay',
    ]),
  },
};
</script>
