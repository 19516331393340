<template>
  <div class="_rfp_mctb_list_10_ rfp-iframe-product-list-row">
    <div class="_rfp_mctb_list_11_ rfp-iframe-product-list-column">
      <div class="_rfp_mctb_list_12_ rfp-iframe-product-list-card">
        <div class="_rfp_mctb_list_14_ rfp-iframe-error" v-html="errorMessage"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'rfp-iframe-error',
  props: {
    errorMessage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.rfp-mctb-product-list-column {
  width: 100%;
}
.rfp-mctb-error {
  font-size: 19px;
  color: red;
}
</style>
