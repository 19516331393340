<template>
  <oap-slider v-if="hasRecommendations" theme="light" layout="product">
    <template #copy>
      <component :is="copy.title.heading" class="oap-slider__title" v-text="copy.title.text" />
      <component
        :is="copy.subtitle.heading"
        class="oap-slider__subtitle"
        v-text="copy.subtitle.text"
      />
    </template>
    <ul class="oap-slider__list">
      <li v-for="item of result.Recommendations" :key="item.id" class="oap-slider__item">
        <oap-card :type="item.type" :item="item" :layouts="[]"> </oap-card>
      </li>
    </ul>
    <template #link>
      <div>
        <a :href="copy.link.href" class="oap-button -secondary">
          {{ copy.link.text }}
        </a>
      </div>
    </template>
  </oap-slider>
</template>

<script>
import { mapGetters } from 'vuex';
import oapCard from './../../../../OapCard/code/Scripts/components/OapCard.vue';
import oapSlider from './../../../../OapSlider/code/Scripts/components/OapSlider.vue';

export default {
  name: 'OapMakeTheSwitchResultRecommendations',

  components: {
    oapCard,
    oapSlider,
  },

  props: {
    copy: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('oapMakeTheSwitchStore', ['result']),

    hasRecommendations() {
      return this.result?.Recommendations?.length > 0;
    },
  },
};
</script>
