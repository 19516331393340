/**
 * Updates the scroll position to the beginning of the quiz
 *
 */
export const scrollToQuiz = (yPosition) => {
  const beautyProfileTop = yPosition - 72;
  const isBeautyProfileTabsVisible = window.scrollY <= beautyProfileTop;
  const firstInput = document.querySelector('.oap-beauty-sections__list > li input');
  const closeQuiz = document.querySelector('.oap-beauty-exit__trigger');

  if (firstInput) {
    firstInput.focus();
  } else if (closeQuiz) {
    closeQuiz.focus();
  }

  return !isBeautyProfileTabsVisible ? window.scrollTo(0, beautyProfileTop) : window.scrollTo(0, 0);
};

/**
 * Finds the IDs of the selected options
 *
 * @param options {Array} - list of options
 * @return {Array} list of IDs of selected options
 */
const optionSelectedAnswersIds = (options) => {
  return options.filter((option) => option.selected).map((option) => option.id);
};

/**
 * Finds the selected options of a question
 *
 * @param questions {Array} - list of questions
 * @return {Array} list of questions IDs concatenated with it's selected options IDs
 */
const selectedAnswers = (questions) => {
  const selected = [];

  if (questions) {
    questions.filter((question) => {
      const optionSelected = optionSelectedAnswersIds(question.options);

      if (optionSelected.length > 0) {
        selected.push(`${question.id}:${optionSelected.join(',')}`);
      }
    });
  }
  return selected;
};

/**
 * Finds the selected answers for each section
 *
 * @param sections {Array} - list of sections
 * @return {Array} list of selected answers for each section
 */
const allSectionsAnswers = (sections) => {
  const answers = [];
  sections.filter((section) => {
    if (section.validated) {
      answers.push(selectedAnswers(section.questions));
    }
  });
  return [].concat.apply([], answers);
};

/**
 * Finds the options that are selected
 *
 * @param option {Object} - question
 * @return {Object} option that is selected
 */
const optionSelected = (option) => {
  if (option.selected) {
    return option;
  }
};

/**
 * Finds all options selected
 *
 * @param question {Object} - question
 * @return {Object} option that is selected
 */
const allOptionsAnswered = (question) => {
  return question.options.filter((option) => optionSelected(option));
};

/**
 * Finds questions that has the minimal required selected options
 *
 * @param question {Object} - question
 * @return {Object} option that has minimal required options selected
 */
const questionHasSelectedOptions = (question) => {
  if (allOptionsAnswered(question).length >= question.minAnswersRequired) {
    return question;
  }
};

/**
 * Checks if section is valid
 *
 * @param section {Object} - section
 * @return {boolean} all the questions within a section have the required minimial options selected
 */
export const isSectionValid = (section) => {
  const questionsWithMinAnswers = section.questions.filter((question) =>
    questionHasSelectedOptions(question)
  );
  return questionsWithMinAnswers.length === section.questions.length;
};

/**
 * Finds the next section
 *
 * @param sections {Array} - sections
 * @param currentSection {Object} - section that is active
 * @return {Object} the section after the active section
 */
export const nextSection = (sections, currentSection) => {
  const current = sections.find((section) => section.id === currentSection.id);
  return sections[sections.indexOf(current) + 1];
};

/**
 * Finds the previous section
 *
 * @param sections {Array} - sections
 * @param currentSection {Object} - section that is active
 * @return {Object} the section before the active section
 */
export const previousSection = (sections, currentSection) => {
  const current = sections.find((section) => section.id === currentSection.id);
  return sections[sections.indexOf(current) - 1];
};

/**
 * Submits the state of the quiz
 *
 * @param apiUrl {string} - api url
 * @param sections {Array} - sections in the quiz
 */
export const sendAnswers = (apiUrl, sections, currentSectionId) => {
  return new Promise((resolve) => {
    fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      credentials: 'same-origin',
      body: JSON.stringify({
        currentSectionId: currentSectionId,
        keyvalues: allSectionsAnswers(sections),
      }),
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        resolve(jsonResponse);
      })
      .catch((err) => console.error(err));
  });
};
