<template>
  <div class="oap-beauty-profile-wrapper">
    <oap-beauty-exit :show-award="showAward" :close-btn-label="closeBtnLabel">
      <template #exit-overlay-title-description>
        <slot name="exit-overlay-title-description" />
      </template>
      <template #exit-overlay-continue-cta-label>
        <slot name="exit-overlay-continue-cta-label" />
      </template>
      <template #exit-overlay-quit-cta-label>
        <slot name="exit-overlay-quit-cta-label" />
      </template>
      <template #exit-overlay-image>
        <slot name="exit-overlay-image" />
      </template>
    </oap-beauty-exit>

    <oap-beauty-award
      v-if="showAward"
      :current-section-id="currentSection.id"
      :award="currentSection.award"
      @keepGoing="keepGoing"
    >
    </oap-beauty-award>

    <section v-else class="oap-beauty-profile">
      <div ref="oapBeautyProfile" class="oap-beauty-profile-gutter">
        <nav :aria-label="stepsNavigationLabel">
          <ul class="oap-beauty-profile__tabs">
            <li v-for="(section, j) in sections" :key="`tab-${j}`">
              <div
                :class="[
                  'oap-beauty-profile__tab-btn',
                  { 'oap-beauty-profile__tab-btn--active': currentSection.id === section.id },
                ]"
              >
                {{ section.title }}
                <span v-if="currentSection.id === section.id" class="isSrOnly">{{
                  currentStepLabel
                }}</span>
              </div>
            </li>
          </ul>
        </nav>
        <ul :class="['oap-beauty-sections', { 'oap-beauty-sections--forward': forward }]">
          <li v-for="(section, i) in sections" :key="i">
            <ul v-if="currentSection.id === section.id" class="oap-beauty-sections__list">
              <li v-for="(question, k) in section.questions" :key="k">
                <div class="oap-beauty-question">
                  <div>
                    <div class="oap-beauty-question__section-info">
                      {{ `${section.title} ${k + 1}/${section.questions.length}` }}
                    </div>
                    <div
                      :id="`question-title-${question.id}`"
                      class="oap-beauty-question__title"
                      v-html="question.title"
                    ></div>
                    <div
                      :id="`question-description-${question.id}`"
                      class="oap-beauty-question__subtitle -desktop"
                      v-html="question.description"
                    ></div>
                  </div>
                  <div>
                    <div
                      :id="`description-${question.id}`"
                      class="oap-beauty-question__description isFontBold"
                    >
                      {{ question.copyLabel }}
                    </div>
                    <oap-beauty-option
                      :question="question"
                      :title-question-ids="`description-${question.id} question-title-${question.id}`"
                      @updated="canMoveForward"
                    />
                  </div>
                  <div
                    :id="`question-description-${question.id}`"
                    class="oap-beauty-question__subtitle -mobile"
                    v-html="question.description"
                  ></div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <oap-beauty-control
          :disable-previous="currentIndex === 0"
          :disable-next="!currentSection.validated || loading"
          :previous-label="currentSection.previousBtnLabel"
          :next-label="currentSection.nextBtnLabel"
          @back="goBack"
          @next="goNext"
        />
      </div>
    </section>
  </div>
</template>

<script>
import oapBeautyOption from './oap-beauty-option.vue';
import oapBeautyControl from './oap-beauty-control.vue';
import oapBeautyExit from './oap-beauty-exit.vue';
import oapBeautyAward from './oap-beauty-award.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  SET_INITIAL_DATA,
  GO_NEXT,
  GO_BACK,
  SET_CURRENT_TAB,
  CHECK_IF_CAN_MOVE_FORWARD,
  SET_QUIZ_ELEMENT_TOP_POSITION,
  SET_AWARD_SCREEN_REACHED,
} from '../mutation-types';

export default {
  name: 'OapBeautyProfile',
  components: {
    oapBeautyOption,
    oapBeautyControl,
    oapBeautyExit,
    oapBeautyAward,
  },
  props: {
    apiUrl: { type: String, required: true },
    initialQuizData: { type: Array, required: true },
    awardSectionIndex: { type: Number, required: false, default: null },
    closeBtnLabel: { type: String, required: false, default: 'close' },
    stepsNavigationLabel: { type: String, required: false, default: 'Steps' },
    currentStepLabel: { type: String, required: false, default: 'current step' },
  },
  data() {
    return {
      oldCurrent: 0,
      tabClick: false,
    };
  },
  computed: {
    ...mapGetters('beautyProfileStore', [
      'sections',
      'currentSection',
      'currentIndex',
      'awardScreenReached',
      'showAwardScreen',
      'loading',
    ]),
    showAward() {
      return this.showAwardScreen && !this.currentSection.skipAwardScreen;
    },
  },
  mounted() {
    this[SET_INITIAL_DATA]({ sections: this.initialQuizData, apiUrl: this.apiUrl });
    this[SET_QUIZ_ELEMENT_TOP_POSITION](this.$refs.oapBeautyProfile.getBoundingClientRect().top);
  },
  methods: {
    ...mapActions('beautyProfileStore', [
      SET_INITIAL_DATA,
      GO_NEXT,
      GO_BACK,
      SET_CURRENT_TAB,
      CHECK_IF_CAN_MOVE_FORWARD,
      SET_QUIZ_ELEMENT_TOP_POSITION,
      SET_AWARD_SCREEN_REACHED,
    ]),
    forward() {
      const goForward = this.currentIndex < this.oldCurrent;
      this.oldCurrent = this.currentIndex;
      return goForward;
    },
    setTabClick(id) {
      this.tabClick = true;

      this[SET_CURRENT_TAB](id);
      setInterval(() => {
        this.tabClick = false;
      }, 500);
    },
    goNext() {
      this[GO_NEXT]();
    },
    goBack() {
      this[GO_BACK]();
    },
    canMoveForward() {
      this[CHECK_IF_CAN_MOVE_FORWARD]();
    },
    keepGoing() {
      this[SET_AWARD_SCREEN_REACHED]();
    },
  },
};
</script>
