<template>
  <div class="colorDropdown">
    <div
      class="colorDropdown__content"
      :class="{
        '-open': expanded,
        '-colorHovered': isColorHovered,
        '-dropdownHovered': isDropdownHovered,
      }"
    >
      <div class="colorDropdown__current">
        <span id="color-label" class="isSrOnly" for="current-color"> Color </span>

        <button
          id="current-color"
          ref="toggler"
          class="colorDropdown__currentColorButton"
          aria-labelledby="color-label current-color"
          :aria-expanded="expanded ? 'true' : 'false'"
          @keyup.esc="close()"
          @click="toggle()"
        >
          {{ selected || getSelectedColor.value }}
        </button>

        <div class="colorDropdown__border" />

        <div
          v-show="!isColorHovered"
          class="colorDropdown__currentColor"
          :style="{
            borderColor: getSelectedColor.hex,
            backgroundColor: getSelectedColor.swatch ? '' : getSelectedColor.hex,
            backgroundImage: `url(${getSelectedColor.swatch})`,
          }"
        >
          <span
            v-show="!isColorHovered"
            class="colorDropdown__currentColorBorder"
            :style="{
              borderColor: getSelectedColor.hex,
              opacity: 0.5,
            }"
          />
        </div>
        <div
          v-show="isColorHovered"
          class="colorDropdown__currentColor"
          :style="{
            borderColor: colorHover,
            backgroundColor: colorHover,
            backgroundImage: `url(${colorSwatch})`,
          }"
        >
          <span
            class="colorDropdown__currentColorBorder"
            :style="{
              borderColor: colorHover,
              opacity: 0.5,
            }"
          />
        </div>
      </div>
      <ul class="colorDropdown__list scrollbarCustom">
        <li v-for="(color, index) in unSelectedColors" :key="index">
          <a
            :key="index"
            :class="{ '-selected': color.prevSelected }"
            class="colorDropdown__item"
            :href="qrCodeScanURL ? `${color.url}${qrCodeScanURL}` : color.url"
            :aria-label="color.ariaLabel"
            @keyup.esc="close()"
            @click.prevent="colorClick(color)"
          >
            <span
              class="colorDropdown__color"
              :style="{
                backgroundImage: `url(${color.swatch})`,
                backgroundColor: `${color.hex}`,
              }"
            />
            {{ color.value }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';

export default {
  name: 'ColorDropdown',
  props: {
    colors: { type: Array, required: true },
    brandName: { type: String, required: true, default: undefined },
    productCategory: { type: String, required: true, default: undefined },
    productVariant: { type: String, required: true, default: undefined },
    productDimension49: { type: String, required: true, default: undefined },
    productDimension91: { type: String, required: true, default: undefined },
    taggingList: { type: String, default: 'product detail page' },
    shouldRedirect: { type: Boolean, default: true },
    colorSelectorComponentName: { type: String, default: '' },
    isDynamicSelection: { type: Boolean, default: false },
  },
  data() {
    return {
      expanded: false,
      selected: false,
      prevSelected: null,
      isColorHovered: false,
      isDropdownHovered: false,
      colorHover: null,
      colorSwatch: null,
      timerMouseOut: 800,
      colorDropdownItems: null,
      qrCodeScanURL: null,
    };
  },

  computed: {
    getSelectedColor() {
      if (!this.colors) return;

      return this.colors.find((c) => c.selected === true);
    },

    unSelectedColors() {
      if (!this.colors) return;

      return this.colors.filter((c) => !c.selected);
    },
  },
  mounted() {
    let url = new URL(window.location.href);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let paramsValue =
      params.utm_medium == 'qrcode' &&
      (params.utm_source == 'pos' || params.utm_retailer.length >= 1);
    if (paramsValue) {
      const getQueryString = url.search;
      this.qrCodeScanURL = getQueryString;
    }
    /* istanbul ignore next */
    this.$nextTick(() => {
      this.colorDropdownItems = document.querySelectorAll('.colorDropdown__item:not(.data__item)');

      Array.prototype.forEach.call(this.colorDropdownItems, (item) => {
        item.addEventListener('blur', this.dropdownBlurHandler);
      });

      this.$refs.toggler.addEventListener('blur', this.dropdownBlurHandler);
      this.prevSelected = this.getSelectedColor.value;
    });

    /* istanbul ignore else */
    if (this.colorSelectorComponentName)
      this.initColorSelectorListeners(this.colorSelectorComponentName);
  },

  /* istanbul ignore next */
  beforeUnmount() {
    this.colorDropdownItems.removeEventListener('blur', this.dropdownBlurHandler);
    this.$refs.toggler.removeEventListener('blur', this.dropdownBlurHandler);
  },

  methods: {
    initColorSelectorListeners(componentName) {
      /* istanbul ignore next */
      eventBus.on(`${componentName}::change`, (colorName) => {
        this.setSelectedByColorValue(colorName);
        this.isColorHovered = true;
      });

      /* istanbul ignore next */
      eventBus.on(`${componentName}::mouseout`, (hovered) => {
        this.isColorHovered = hovered;
        this.selected = this.isDynamicSelection ? null : this.prevSelected;
      });

      /* istanbul ignore next */
      eventBus.on(`${componentName}::shadow`, (value) => {
        this.isDropdownHovered = value;
      });
      /* istanbul ignore next */
      eventBus.on(`${componentName}::colorSwatch`, (colorSwatch) => {
        if (colorSwatch !== '' || colorSwatch !== undefined) {
          this.colorSwatch = colorSwatch;
        }
      });
      /* istanbul ignore next */
      eventBus.on(`${componentName}::colorHex`, (colorHex) => {
        this.colorHover = colorHex;
      });

      /* istanbul ignore next */
      eventBus.on(`${componentName}::select`, this.colorClick);
    },

    colorClick(color) {
      /* istanbul ignore next */
      this.pushTaggingInfo(color);
      /* istanbul ignore next */
      if (this.shouldRedirect && !this.qrCodeScanURL) {
        window.location.assign(color.url);
        return;
      }
      // eslint-disable-next-line no-dupe-else-if
      else if (this.shouldRedirect && this.qrCodeScanURL) {
        window.location.assign(`${color.url}${this.qrCodeScanURL}`);
        return;
      }
      eventBus.emit(`${this.$options.name}::change`, color.ean);
      this.expanded = false;
      this.isColorHovered = false;
    },

    pushTaggingInfo(color) {
      const taggingObj = {
        event: 'uaevent',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Detail|Variant selection',
        eventLabel: `${color.name}::${color.ean}::${color.value}`.toLowerCase(),
        event_name: 'view_item',
        event_detail: `variant selection`,
        product_info: `${color.name}::${color.ean}::${color.value}`.toLowerCase(),
        ecommerce: {
          detail: {
            actionField: {
              list: 'detail',
              action: 'detail',
            },
            products: [
              {
                brand: this.brandName,
                category: this.productCategory,
                dimension36: `${color.value}`.toLowerCase(),
                dimension38: color.dimension38,
                dimension48: `${color.ean}`.toLowerCase(),
                dimension49: this.productDimension49,
                dimension91: this.productDimension91,
                id: `${color.ean}`.toLowerCase(),
                name: `${color.name}`.toLowerCase(),
                position: this.colors.indexOf(color) + 1,
                price: color.price,
                variant: this.productVariant,
              },
            ],
          },
        },
      };

      /* istanbul ignore else */
      if (color.dimension35) {
        taggingObj.products[0].dimension35 = color.dimension35;
      }

      window.dataLayer.push(taggingObj);
    },

    close() {
      this.expanded = false;
      this.$refs.toggler.focus();
    },

    toggle() {
      this.expanded = !this.expanded;

      /* istanbul ignore next */
      if (this.expanded) {
        eventBus.emit(`${this.$options.name}::toggle`, true);
      }
    },

    setSelectedByColorValue(color) {
      this.selected = color;
    },

    dropdownBlurHandler() {
      setTimeout(() => {
        let activeElementClass;

        /* istanbul ignore next */
        if (document.documentMode || /Edge/.test(navigator.userAgent)) {
          /* eslint-disable prefer-destructuring */
          activeElementClass = document.activeElement.classList[0] || null;
        } else {
          activeElementClass = document.activeElement.classList.value;
        }

        if (
          !(
            activeElementClass === 'colorDropdown__item' ||
            activeElementClass === this.$refs.toggler.classList.value
          ) &&
          this.expanded === true
        ) {
          this.expanded = false;
        }
      }, 300);
    },
  },
};
</script>
