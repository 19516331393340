<template>
  <div class="commerceConnector" :class="{ '-expanded': expandPanel }" :aria-hidden="ariaHidden">
    <div v-if="expandPanel" class="commerceConnector__overlay" @click="switchPanel(false)"></div>
    <div
      class="commerceConnector__layout"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-heading"
    >
      <button
        ref="commerceConnectorCloseButton"
        class="commerceConnector__close commerceConnector__tabIndex"
        tabindex="-1"
        @click="switchPanel(false)"
        @keyup.esc="switchPanel(false)"
        @keydown.shift.tab="shiftTabKeyupEvent"
        @keypress.enter="buyOnlineFocus()"
      >
        <svg class="icon" role="img" aria-labelledby="closeCommerceConnector">
          <title id="closeCommerceConnector">Close window</title>
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close">
            {{ closeIconText }}
          </use>
        </svg>
      </button>
      <form>
        <div class="commerceConnector__wrapper">
          <fieldset>
            <template v-if="shopsEmpty || eretailerData.shopsUnavailable">
              <legend class="commerceConnector__description -shopsNotAvailable">
                <h3 id="modal-heading">{{ eretailerData.defaultDescriptionTitle }}</h3>
              </legend>
            </template>
            <template v-if="!eretailerData.shopsUnavailable && shopsNotEmpty">
              <legend class="commerceConnector__description">
                <h1 id="modal-heading">
                  <strong>{{ eretailerData.descriptionProduct }}</strong>
                  {{ eretailerData.descriptionText }}
                </h1>
              </legend>

              <ul class="commerceConnector__shops" :class="{ '-oneShopLayout': oneShopLayout }">
                <li
                  v-for="shopData in eretailerData.eretailershops"
                  :key="shopData.id"
                  class="commerceConnector__item"
                >
                  <span
                    v-if="shopData.unavailable === true"
                    class="commerceConnector__radioLabel"
                    :class="{ '-unavailable': shopData.unavailable }"
                  >
                    <img class="commerceConnector__logo" :src="shopData.Image" alt="" />
                    {{ shopData.title }}
                  </span>
                  <a
                    v-else
                    :href="shopData.link"
                    class="commerceConnector__radioLabel commerceConnector__tabIndex"
                    :class="{ '-unavailable': shopData.unavailable }"
                    target="_blank"
                    @click="
                      getTaggingInfo(
                        shopData.taggingInfo.eventAction,
                        shopData.taggingInfo.eventLabel,
                        shopData.taggingInfo.ecommerce
                      )
                    "
                  >
                    <img class="commerceConnector__logo" :src="shopData.Image" alt="" />
                    {{ shopData.title }}
                  </a>
                </li>
              </ul>
            </template>
          </fieldset>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '../../../../../../Foundation/Core/code/Scripts/analyticsHandler/AnalyticsHandler';

export default {
  name: 'Eretailer',
  props: {
    initialData: { type: Object, required: true },
    closeIconText: { type: String, required: false, default: 'Close window' },
  },
  data() {
    return {
      shopData: {},
      eretailerData: this.initialData,
      expandPanel: false,
      ariaHidden: true,
      shopCountNo: 0,
      retailerUrl: '',
      buyMessage: '',
      productName: '',
      taggingAction: '',
      taggingLabel: '',
    };
  },
  computed: {
    shopsEmpty() {
      return this.initialData.eretailershops.length === 0;
    },
    shopsNotEmpty() {
      return this.initialData.eretailershops.length !== 0;
    },
    oneShopLayout() {
      return this.initialData.eretailershops.length === 1;
    },
    firstProductName() {
      return this.shopsNotEmpty && this.initialData.eretailershops[0].productName;
    },
    firstBuyButton() {
      return this.shopsNotEmpty && this.initialData.eretailershops[0].buyButton;
    },
    firstLink() {
      return this.shopsNotEmpty && this.initialData.eretailershops[0].link;
    },
  },
  mounted() {
    /* istanbul ignore next */
    eventBus.emit('commerceconnector:ready');

    // to avoid hiding of Buy button on touch devices
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    /* istanbul ignore next */
    eventBus.on('commerceconnector', (isConnectorOpened) => {
      this.switchPanel(isConnectorOpened);
    });

    /* istanbul ignore next */
    window.addEventListener(
      'orientationchange',
      () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        this.switchPanel(false);
      },
      false
    );

    this.displayFirstShop();

    this.escapeEvent();
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange');
  },
  methods: {
    buyOnlineFocus() {
      this.focusBuyOnline();
      this.switchPanel(false);
    },
    switchPanel(isConnectorOpened) {
      if (isConnectorOpened) {
        this.ariaHidden = false;
        this.focusCloseButton();

        document.querySelector('body').classList.add('noScroll', '-fixed');
      } else {
        this.ariaHidden = true;

        document.querySelector('body').classList.remove('noScroll', '-fixed');
      }
      this.expandPanel = isConnectorOpened;
    },
    getRetailerUrl(url, buyMsg, prdName, taggingA, taggingL) {
      this.retailerUrl = url;
      this.buyMessage = buyMsg;
      this.productName = prdName;
      this.taggingAction = taggingA;
      this.taggingLabel = taggingL;
    },
    getTaggingInfo(action, label, ecommerce) {
      const handBuyactive = document.querySelector('.commerceConnector__item');
      const handBuyClick = handBuyactive.classList.contains('handleBuy__eRetailer');
      const handBuypageURL = new URLSearchParams(window.location.search);
      const handBuylastURL = handBuypageURL.has('utm_medium');
      if (handBuyClick === true || handBuylastURL === true) {
        ecommerce.add.products[0].dimension49 = 'activated';
      }
      /* istanbul ignore next */
      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'addToCart',
        category: 'Ecommerce',
        action,
        label,
        ecommerce,
      });
    },
    displayFirstShop() {
      if (this.firstProductName) {
        this.getRetailerUrl(this.firstLink, this.firstBuyButton, this.firstProductName);
        if (document.querySelector('.commerceConnector__radioButton')) {
          const radios = document.querySelectorAll('.commerceConnector__radioButton');
          const firstRadio = radios[0];
          firstRadio.click();

          const label = document.querySelectorAll('.commerceConnector__radioLabel');
          const firstLabel = label[0];
          firstLabel.click();
        }
      }
    },
    focusCloseButton() {
      setTimeout(() => {
        this.$refs.commerceConnectorCloseButton.focus();
      }, 800);
    },
    escapeEvent() {
      document.addEventListener('keydown', (event) => {
        if (
          document.querySelector('.commerceConnector.-expanded') &&
          document.querySelector('.isUsingKeyboard')
        ) {
          event = event || window.event;
          if (event.keyCode == 27) {
            this.switchPanel(false);
            setTimeout(() => {
              this.focusBuyOnline();
            }, 800);
          }
        }
      });
    },
  },
};
</script>
