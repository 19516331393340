<template>
  <div class="oap-make-the-switch-results-page__header">
    <template v-if="hasResults">
      <div class="title">
        <component :is="title.heading" class="title-container">
          <span class="title-label">{{ title.text }}</span>
          <span class="title-product">{{
            result.Product.title + ' ' + result.Product.tag.common.product.dimension36
          }}</span>
        </component>
      </div>
      <div class="product">
        <OapCard :type="result.Product.type" :item="result.Product" :layouts="[]"> </OapCard>
        <div class="product-actions" data-tag-wrapper>
          <a
            v-if="showVtoButton"
            v-tag:useractionevent.dom
            class="oap-button -primary -theme-crimson"
            :data-tag-action="tag.common.action"
            :data-tag-category="tag.common.category"
            :data-tag-label="tag.email.label.replace('${a}', vtoButton.label)"
            data-tag-wrapper
            :href="vtoButtonHref"
          >
            <img v-if="vtoButton.icon" alt="" class="oap-button__img" :src="vtoButton.icon" />
            <span class="oap-button__label">{{ vtoButton.label }}</span>
          </a>

          <div v-if="showEmailFeature" class="oap-iframe-modal">
            <button
              v-tag:useractionevent.dom
              class="oap-button -secondary"
              :data-tag-action="tag.common.action"
              :data-tag-category="tag.common.category"
              :data-tag-label="tag.email.label.replace('${a}', emailButton.label)"
              data-tag-wrapper
              @click="openModal"
            >
              <img v-if="emailButton.icon" alt="" class="oap-button__img" :src="emailButton.icon" />
              <span class="oap-button__label" v-text="emailButton.label" />
            </button>

            <modal :has-backdrop-click="false" modalidentifier="make-the-switch">
              <OapIframe
                height-screen-large="100%"
                height-screen-small="100%"
                :src="emailIframe.src"
                :title="emailIframe.title"
              ></OapIframe>
            </modal>
          </div>
        </div>
        <p class="product-description">{{ result.Product.shortDescription }}</p>
        <p class="product-description">{{ subDescription }}</p>
      </div>
    </template>
    <slot v-else name="no-results" :start-over="startOver" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import eventBus from '@loreal/eventbus-js';
import modal from '../../../../Modal/code/Scripts/components/modal.vue';
import OapCard from '../../../../OapCard/code/Scripts/components/OapCard.vue';
import OapIframe from '../../../../OapIframe/code/Scripts/components/oap-iframe.vue';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

export default {
  name: 'OapMakeTheSwitchResultProduct',

  components: {
    modal,
    OapCard,
    OapIframe,
  },

  props: {
    emailButton: {
      default: null,
      type: Object,
    },
    subDescriptionTemplate: {
      default: '',
      type: String,
    },
    tag: {
      required: true,
      type: Object,
    },
    title: {
      default: () => ({
        heading: 'h2',
        text: '',
      }),
      type: Object,
    },
    vtoButton: {
      default: null,
      type: Object,
    },
  },

  computed: {
    ...mapGetters('oapMakeTheSwitchStore', ['answersHistory', 'result']),

    emailIframe() {
      const answersIds = this.answersHistory.map((history) => history.answer.id);
      const iframeProp = this.emailButton.iframe;
      const iframeUrl = new URL(window.location.origin + iframeProp.src);
      const result = this.result;
      const resultIds = [result.Product, ...result.Recommendations].map(
        (product) => product.itemId
      );

      iframeUrl.searchParams.append('answers', encodeURIComponent(answersIds));
      iframeUrl.searchParams.append('products', encodeURIComponent(resultIds));

      return {
        src: iframeUrl.href,
        title: iframeProp.title,
      };
    },

    hasResults() {
      return !!this.result?.Product;
    },

    showEmailFeature() {
      const { enabled, iframe } = this.emailButton;
      return enabled && !!iframe?.src;
    },

    showVtoButton() {
      const { Product } = this.result;
      return this.vtoButton.enabled && Product.gradient && !!Product.url;
    },

    subDescription() {
      const productReference = this.answersHistory.reduce(
        (acc, curr, i) => (i === 0 ? acc : acc.concat(' ', curr.answer.label)),
        ''
      );
      return this.subDescriptionTemplate.replace('${a}', productReference);
    },

    vtoButtonHref() {
      return `${this.result.Product.url}?tryon=true`;
    },
  },

  mounted() {
    if (!this.hasResults) {
      AnalyticsHandler.getAnalyticsHandler().push({
        action: this.tag.common.action,
        category: this.tag.common.category,
        label: this.tag.noResults.label,
        type: 'userActionEvent',
      });
    }
  },

  methods: {
    ...mapActions('oapMakeTheSwitchStore', ['startOver']),
    openModal() {
      eventBus.emit('modal:make-the-switch');
    },
  },
};
</script>
