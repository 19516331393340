<template>
  <div class="channel-advisor-modal">
    <OapPopup
      :description="internalPopupDescription"
      :open="openModal"
      :hide-overflow="true"
      :aside="true"
      :title="internalPopupTitle"
      @closed="close"
    >
      <iframe v-if="openModal" :src="updateUrl" :title="internalIframeTitle" />
      <template #close>
        <button
          :aria-label="internalPopupCloseLabel"
          class="channel-advisor-modal__exit"
          @click="close"
        >
          <svg class="icon" aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
          </svg>
        </button>
      </template>
    </OapPopup>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '@Foundation';
import { mixinParseURL } from '@Foundation/mixins/parseURL/parseURL';
import OapPopup from '../../../../OapPopup/code/Scripts/components/OapPopup.vue';

const CHANNEL_ADVISOR_URL_PARAM_BUY_KEY = 'buy';
const CHANNEL_ADIVISOR_URL_SINGLE_RETAILER = 'validOnlineRetailers';
const VALID_ONLINE_RETAILER_KEY = 'valid_online_retailer';

export default {
  name: 'OapChannelAdvisor',

  directives: {
    eventBus,
  },

  components: {
    OapPopup,
  },

  mixins: [mixinParseURL],

  props: {
    iframeTitle: { type: String, default: '' },
    url: { type: String, default: '' },
    product: { type: Object, required: true },
    currency: { type: String, required: true },
    isRetailerEnabled: { type: Boolean, default: false },
    expireTime: { type: Number, default: 30 },
    popupCloseLabel: { default: '', type: String },
    popupDescription: { default: '', type: String },
    popupTitle: { default: '', type: String },
    autoOpenEnabled: { type: Boolean, default: true },
  },

  data() {
    return {
      openModal: false,
      retailerInfo: '',
      internalUrl: this.url,
      internalProduct: this.product,
      internalPopupTitle: this.popupTitle,
      internalIframeTitle: this.iframeTitle,
      internalPopupDescription: this.popupDescription,
      internalPopupCloseLabel: this.popupCloseLabel,
      internalIsRetailerEnabled: this.isRetailerEnabled,
    };
  },

  computed: {
    updateUrl() {
      return `${this.internalUrl}&${this.retailerInfo}`;
    },
  },

  mounted() {
    eventBus.on('oap-channel-advisor:open', this.open);
    eventBus.on('oap-channel-advisor:load', this.loadProduct);
    window.addEventListener('message', this.handleIframeMessage, false);
    eventBus.on('sameShade_CA', this.open);
    if (this.URLHasKey(CHANNEL_ADVISOR_URL_PARAM_BUY_KEY) && this.autoOpenEnabled) {
      this.open();
    }
  },

  beforeDestroy() {
    window.removeEventListener('message', this.handleIframeMessage, false);
  },

  methods: {
    close() {
      this.openModal = false;
    },

    loadProduct(action) {
      const event = action.event;
      event.preventDefault();
      event.stopPropagation();

      let product;
      if (typeof action.payload === 'string') {
        product = JSON.parse(action.payload);
      } else {
        product = action.payload;
      }

      this.internalProduct = product;
      this.internalUrl = product.channelAdvisorUrl;
      this.internalIframeTitle = product.iframeTitle;
      this.internalPopupTitle = product.popupTitle;
      this.internalPopupCloseLabel = product.popupCloseLabel;
      this.internalPopupDescription = product.popupDescription;
      this.internalIsRetailerEnabled = product.enableRetailerExclusivity;

      this.open();
    },

    getDimension49() {
      const currentUrl = new URL(window.location.href);
      const initialProductDimension49 = this.internalProduct.dimension49;
      const urlProductDimension49 = currentUrl.searchParams.get('utm_medium') === 'vto';
      const windowDimension49 = window.vtoActivated;

      if (urlProductDimension49 || windowDimension49) {
        return 'activated';
      }

      return initialProductDimension49;
    },

    open() {
      this.handleRetailerInformation();
      this.openModal = true;
    },

    handleIframeMessage(message) {
      const hasTaggingEvent =
        message &&
        message.data &&
        typeof message.data === 'string' &&
        message.data.startsWith('uaevent');

      if (hasTaggingEvent) {
        const currentUrl = new URL(window.location.href);
        const dimension49 = this.getDimension49();
        const dimension91 = currentUrl.searchParams.get('prescripted') || 'none';
        var eventArray = message.data.split('|');

        const { name, id } = this.internalProduct;
        const eventLabel = name && id ? `${name}::${id}` : eventArray[4];
        var arr = eventArray[3].split('::');
        var store = arr[1];
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'addToCart',
          event: eventArray[0],
          category: eventArray[2] || 'ecommerce',
          action: eventArray[3] || 'empty eventAction from Channel Advisor',
          label: eventLabel || 'empty eventLabel from Channel Advisor',
          step: 'redirection',
          productInfo: eventLabel || 'empty productInfo from Channel Advisor',
          event_name: 'add_to_cart',
          storeName: store,
          ecommerce: {
            currencyCode: this.currency,
            add: {
              products: [
                {
                  ...this.internalProduct,
                  dimension49,
                  dimension91,
                },
              ],
            },
          },
        });
      }
    },

    handleRetailerInformation() {
      if (this.internalIsRetailerEnabled) {
        const retailer = this.findInCookies(VALID_ONLINE_RETAILER_KEY);
        if (this.URLHasKey(CHANNEL_ADIVISOR_URL_SINGLE_RETAILER)) {
          const url = new URL(window.location.href);
          const value = url.searchParams.get(CHANNEL_ADIVISOR_URL_SINGLE_RETAILER);
          this.generateCookie(value);
          this.retailerInfo = `${CHANNEL_ADIVISOR_URL_SINGLE_RETAILER}=${value}`;
        } else if (retailer.valid) {
          this.retailerInfo = `${CHANNEL_ADIVISOR_URL_SINGLE_RETAILER}=${retailer.value}`;
        } else {
          this.retailerInfo = '';
        }
      }
    },

    generateCookie(retailer) {
      const expireDate = new Date();
      const minutes = this.expireTime;
      expireDate.setTime(expireDate.getTime() + minutes * 60 * 1000);
      document.cookie = `${VALID_ONLINE_RETAILER_KEY}=${retailer}; expires=${expireDate.toUTCString()}; SameSite=Strict; Secure;`;
    },

    findInCookies(key) {
      const cookies = document.cookie.split(';');

      if (!cookies.some((item) => item.trim().startsWith(`${key}=`))) {
        return {
          value: undefined,
          valid: false,
        };
      }

      const cookie = cookies.find((c) => c.trim().startsWith(`${key}=`));

      if (cookie) {
        return {
          value: cookie.split(',')[0].split('=')[1],
          valid: true,
        };
      }
    },
  },
};
</script>
