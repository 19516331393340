<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="real-dialog">
    <ul ref="chat" class="real-dialog__chat">
      <li v-if="$slots['initial-message-copy']" class="real-dialog__chat__message">
        <slot name="initial-message-copy" />
      </li>
      <li
        v-for="(message, index) in chatMessages"
        :ref="`chat-message--${index}`"
        :key="index"
        class="real-dialog__chat__message"
      >
        <span v-html="message" />
      </li>
    </ul>

    <div class="real-dialog__question">
      <div class="real-dialog__question__form">
        <div class="real-dialog__question__input__wrapper">
          <label class="real-dialog__question__copy" for="question-input">
            {{ questionCopy }}
            <input
              id="question-input"
              v-model="question"
              class="real-dialog__question__input"
              :class="{
                'real-dialog__question__input--error': showInputError,
              }"
              type="text"
              @focus="(e) => handleFocus(e)"
              @blur="(e) => handleFocusOut(e)"
              @keyup="inputKeyUp"
            />
          </label>
          <ul
            v-show="showAutocompleteTopics"
            class="real-dialog__question__autocomplete"
            @click="(event) => ask(event.target.textContent)"
            @keyup="(event) => autocompleteTopicKeyUp(event, topic)"
          >
            <li
              v-for="(topic, index) in autocompleteTopics"
              :key="index"
              class="real-dialog__question__autocomplete__item"
              tabindex="0"
            >
              <span v-html="topic" />
            </li>
          </ul>
        </div>

        <button class="real-dialog__button" @click="ask(question)" v-text="submitCopy" />
      </div>

      <span
        v-show="showInputError"
        class="real-dialog__question__input__error-message"
        v-text="questionInputErrorCopy"
      />
    </div>

    <div class="real-dialog__feedback__area">
      <div v-show="showFeedbackQuestion" class="real-dialog__feedback">
        <span class="real-dialog__feedback__question-copy" v-text="feedbackQuestionCopy" />
        <div class="real-dialog__feedback__button__wrapper">
          <button
            class="real-dialog__button"
            @click="feedbackAnswer(true)"
            v-text="feedbackButtonYesCopy"
          />
          <button
            class="real-dialog__button"
            @click="feedbackAnswer(false)"
            v-text="feedbackButtonNoCopy"
          />
        </div>
      </div>
    </div>

    <div class="real-dialog__related-topics__area">
      <div v-show="showRelatedTopics" class="real-dialog__related-topics">
        <span class="real-dialog__related-topics__copy" v-text="relatedTopicsCopy" />

        <ul v-show="relatedTopics.length > 0">
          <li v-for="topic in relatedTopics" :key="topic">
            <button
              class="real-dialog__related-topics__button"
              @click="ask(topic)"
              v-text="topic"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { debounce } from '../../../../../Foundation/Core/code/Scripts';

export default {
  name: 'RealDialog',

  props: {
    feedbackButtonNoCopy: {
      default: 'No',
      type: String,
    },
    feedbackButtonNoLink: {
      default: null,
      type: String,
    },
    feedbackButtonYesCopy: {
      default: 'Yes',
      type: String,
    },
    feedbackQuestionCopy: {
      default: 'Did we answer your question?',
      type: String,
    },
    questionCopy: {
      default: 'Enter your question',
      type: String,
    },
    questionInputErrorCopy: {
      default: '',
      type: String,
    },
    relatedTopicsCopy: {
      default: 'Related topics',
      type: String,
    },
    submitCopy: {
      default: 'Submit',
      type: String,
    },
    userNameCopy: {
      default: 'You',
      type: String,
    },
    webServicePrefix: {
      required: true,
      type: String,
    },
  },

  data: () => ({
    autocompleteTopics: [],
    chatMessages: [],
    hideAutocompleteTopics: false,
    question: '',
    questionInputError: false,
    relatedTopics: [],
    sessionId: '',
    showFeedbackQuestion: false,
    showRelatedTopics: false,
  }),
  computed: {
    showInputError() {
      return this.question.length === 0 && this.questionInputError;
    },

    showAutocompleteTopics() {
      return !this.hideAutocompleteTopics && this.autocompleteTopics.length > 0;
    },
  },

  watch: {
    chatMessages: {
      deep: true,
      handler() {
        this.$nextTick(() => this.scrollToCurrentAnswer());
      },
    },
  },

  mounted() {
    this.initializeRealDialog();
  },

  methods: {
    handleFocus() {
      if (!this.autocompleteTopics) return;
      this.setHideAutocompleteTopics(false);
    },
    handleFocusOut(e) {
      /**
       * If triggered by an interaction with a different event (clicking on a result)
       * don't do anything
       *  */
      if (e.relatedTarget) return;
      this.setHideAutocompleteTopics(true);
    },

    autocompleteTopicKeyUp(event, topic) {
      if (event.key === 'Enter') {
        this.ask(topic);
      }
    },

    ask(question) {
      if (question.length < 1) {
        this.questionInputError = true;

        return;
      }

      this.questionInputError = false;
      this.chatMessages.push(
        `<span class='real-dialog__chat__user-name'>${this.userNameCopy}</span>: ${question}`
      );
      this.getAnswer(question);
      this.question = '';
      this.autocompleteTopics = [];
      this.hideAutocompleteTopics = true;
    },

    feedbackAnswer(answer) {
      if (answer) {
        this.showFeedbackQuestion = false;
      } else {
        if (this.feedbackButtonNoLink) {
          window.location.href = this.feedbackButtonNoLink;
        } else {
          this.showFeedbackQuestion = false;
        }
      }
    },

    getAnswer(question) {
      const endpoint = `${this.webServicePrefix}/ask?question=${question}&sessionid=${this.sessionId}`;
      const handleResponse = (response) => {
        const { data } = response;

        this.showRelatedTopics = true;

        if (data.SessionId) {
          this.sessionId = data.SessionId;
        }

        if (data.Answer) {
          this.chatMessages.push(
            `<span class='real-dialog__chat__user-name'>L'Oréal</span>: ${data.Answer}`
          );
          this.showFeedbackQuestion = true;
        }

        if (data.RelatedTopics && Object.values(data.RelatedTopics).length > 0) {
          this.relatedTopics = Object.values(data.RelatedTopics);
        }
      };

      axios.get(endpoint).then(handleResponse);
    },

    getAutocomplete(question) {
      if (question.length < 1) {
        return;
      }

      const endpoint = `${this.webServicePrefix}/autocomplete?question=${question}&sessionid=${this.sessionId}`;
      const handleResponse = (response) => {
        const { data } = response;

        if (this.question.length > 0) {
          this.autocompleteTopics = data.Suggestions || [];
          this.hideAutocompleteTopics = false;
        }
      };

      axios.get(endpoint).then(handleResponse);
    },

    /**
     * RealDialog requires a session ID to be sent with each question.
     * This call sends nothing by default and will return a SessionID
     * that we store for all subsequent API calls.
     */
    initializeRealDialog() {
      const endpoint = `${this.webServicePrefix}/ask`;
      const handleResponse = (response) => {
        const { data } = response;

        if (data.SessionId) {
          this.sessionId = data.SessionId;
        }
      };

      axios.get(endpoint).then(handleResponse);
    },

    /**
     * Turn this into a property attached/created in the `created()` hook if multiple instances
     * of the component will be available on the page.
     */
    inputKeyUp: debounce(function (event) {
      if (event.key === 'Enter') {
        this.ask(this.question);
        return;
      }

      const invalidKeys = ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'];
      if (!invalidKeys.includes(event.key)) {
        if (this.question.length > 0) {
          this.getAutocomplete(this.question);
        } else {
          this.autocompleteTopics = [];
          this.hideAutocompleteTopics = true;
        }
        return;
      }
    }, 600),

    scrollToCurrentAnswer() {
      const chatElement = this.$refs.chat;
      const lastChatMessageIndex = this.chatMessages.length - 1;
      const lastChatMessageElement = this.$refs[`chat-message--${lastChatMessageIndex}`][0];
      const scrollAdjustment = 30;
      const targetOffset =
        chatElement.scrollHeight - lastChatMessageElement.offsetHeight - scrollAdjustment;

      chatElement.scrollTop = targetOffset;
    },

    setHideAutocompleteTopics(value) {
      this.hideAutocompleteTopics = value;
    },
  },
};
</script>
