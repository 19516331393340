import OapFavorite from './code/Scripts/components/oap-favorite.vue';
import oapFavoriteStore from './code/Scripts/store';

export default {
  components: {
    OapFavorite,
  },
  modules: {
    oapFavoriteStore,
  },
};
