<template>
  <div class="oap-product-variant-selector">
    <slot :variants="variants" />
  </div>
</template>

<script>
export default {
  name: 'OapProductVariantSelector',
  props: {
    variants: { type: Array, required: true },
  },
};
</script>
