<template></template>
<script>
export default {
  name: 'rfp-iframe-loader',
  props: {
    url: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    frame: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'a',
    },
    defaults: {
      type: String,
      required: false,
    },
    dataLayers: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      default: this.defaults,
    };
  },
  mounted() {
    if (this.text === '') {
      this.loadIframe();
    }
  },
  methods: {
    /* istanbul ignore next */
    loadIframe() {
      /* istanbul ignore next */
      window.eventBus.emit(this.frame);
      /* istanbul ignore next */
      const iframe = document.getElementById(this.frame);
      /* istanbul ignore next */
      iframe.src = this.url;
    },
    /* istanbul ignore next */
    loadIframeLoad() {
      /* istanbul ignore next */
      this.loadIframe();
      /* istanbul ignore next */
      if (window.dataLayer !== undefined) {
        /* istanbul ignore next */
        if (this.dataLayers !== '') {
          /* istanbul ignore next */
          dataLayer.push(JSON.parse(this.dataLayers)); // eslint-disable-line no-undef
        }
      }
    },
  },
  render: function (createElement) {
    return createElement(
      this.type, // tag name
      {
        domProps: {
          innerHTML: this.text,
        },
        on: {
          click: this.loadIframeLoad,
        },
      }
    );
  },
};
</script>
