<template>
  <div
    v-if="showColorSelectors"
    class="colorSelector"
    @mouseover="hoverOnList"
    @mouseout="leaveOnList"
  >
    <ul class="colorSelector__list">
      <li
        v-for="(color, index) in colors"
        :key="index"
        class="colorSelectorItem colorItem"
        :class="{
          '-light': !color.isDark,
          '-active': color.selected,
          sameitem: color.sameitem,
          activeitem: color.activeitem,
        }"
      >
        {{ color.sameitem }}
        {{ color.parentItemID }}
        {{ color.itemID }}
        <a
          :href="qrCodeScanURL ? `${color.url}${qrCodeScanURL}` : color.url"
          class="colorSelectorLink"
          :aria-label="color.ariaLabel"
          :aria-current="color.selected ? true : null"
          @mouseover="hoverOnColor(color)"
          @focus="hoverOnColor(color)"
          @mouseout="leaveOnColor()"
          @click.prevent="selectColor(color)"
          @blur="leaveOnColor()"
        >
          <span
            class="colorItemRound colorSelectorItemRound -border"
            :style="{ 'background-color': color.hex }"
          >
          </span>

          <span
            v-if="color.swatch"
            class="colorItem__circle colorSelectorItem__circle -swatch"
            :style="{ 'background-image': `url(${color.swatch})` }"
          >
          </span>

          <svg
            v-else
            class="colorItem__circle colorSelectorItem__circle"
            width="140"
            height="140"
            role="img"
            :aria-labelledby="`aria-id-${color.ean}`"
            :fill="color.hex"
            :stroke="color.hex"
          >
            <title :id="`aria-id-${color.ean}`">{{ color.value }}</title>

            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#disc"></use>
          </svg>

          <svg class="colorItem__checked colorSelectorItem__checked" :aria-hidden="true">
            <title>{{ color.name }}</title>
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#check"></use>
          </svg>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';

export default {
  name: 'ColorSelector',
  props: {
    colors: { type: Array, required: true },
  },
  data() {
    return {
      qrCodeScanURL: null,
      showColorSelectors: false,
    };
  },
  mounted() {
    let url = new URL(window.location.href);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let paramsValue =
      params.utm_medium == 'qrcode' &&
      (params.utm_source == 'pos' || params.utm_retailer.length >= 1);
    if (paramsValue) {
      const getQueryString = url.search;
      this.qrCodeScanURL = getQueryString;
    }
    this.initColorSelectOnLoad();
  },
  methods: {
    selectColor(color) {
      eventBus.emit(`${this.$options.name}::select`, color);
    },

    hoverOnList() {
      eventBus.emit(`${this.$options.name}::shadow`, true);
    },

    leaveOnList() {
      eventBus.emit(`${this.$options.name}::shadow`, false);
    },

    hoverOnColor({ value, hex, swatch }) {
      eventBus.emit(`${this.$options.name}::change`, value);
      eventBus.emit(`${this.$options.name}::colorHex`, hex);
      eventBus.emit(`${this.$options.name}::colorSwatch`, swatch);
    },

    leaveOnColor() {
      eventBus.emit(`${this.$options.name}::mouseout`, false);
    },
    initColorSelectOnLoad() {
      window.addEventListener('load', () => {
        this.showColorSelectors = true;
      });
    },
  },
};
</script>
