<template>
  <div class="oap-make-the-switch">
    <slot v-if="!(display === 'cover')" name="top-nav" />

    <slot v-if="display === 'quiz'" name="quiz" />

    <slot v-else-if="display === 'result'" name="result" />

    <slot v-else name="cover" :update-display="updateDisplay" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OapMakeTheSwitch',

  computed: {
    ...mapGetters('oapMakeTheSwitchStore', ['display']),
  },

  methods: {
    ...mapActions('oapMakeTheSwitchStore', ['updateDisplay']),
  },
};
</script>
