<template>
  <div class="oap-make-the-switch-top-nav">
    <button class="oap-make-the-switch-top-nav__button" @click="buttonAction">
      <div :aria-label="backButtonArrowLabel" class="oap-make-the-switch-top-nav__button-arrow" />
      <span class="oap-make-the-switch-top-nav__button-label" v-text="buttonLabel" />
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OapMakeTheSwitchTopNav',

  props: {
    backButtonArrowLabel: {
      default: '',
      type: String,
    },
    startOverButtonLabel: {
      default: '',
      type: String,
    },
  },

  computed: {
    ...mapGetters('oapMakeTheSwitchStore', ['currentQuestion', 'display']),

    buttonLabel() {
      return this.isOnResultsPage
        ? this.startOverButtonLabel
        : this.currentQuestion.backButtonLabel;
    },

    isOnResultsPage() {
      return this.display === 'result';
    },
  },

  methods: {
    ...mapActions('oapMakeTheSwitchStore', ['goBack', 'startOver']),

    buttonAction() {
      this.isOnResultsPage ? this.startOver() : this.goBack();
    },
  },
};
</script>
