import oapBeautyProfile from './code/Scripts/components/oap-beauty-profile.vue';

import beautyProfileStore from './code/Scripts/store';

export default {
  components: {
    oapBeautyProfile,
  },
  modules: {
    beautyProfileStore: beautyProfileStore,
  },
};
