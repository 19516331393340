export const MUTATE = 'mutate';
export const SET_CURRENT_SECTION = 'setCurrent';
export const SET_CURRENT_SECTION_INDEX = 'setCurrentIndex';
export const SET_SECTION_VALIDATED = 'setValidated';
export const SET_SECTION_REACHED = 'setReached';
export const SET_SECTION_LOCKED = 'setLockedSections';
export const SET_INITIAL_VALIDATED_SECTIONS = 'setInitialValidatedSections';
export const SET_INITIAL_REACHED_SECTIONS = 'setInitialReachedSections';
export const SET_INITIAL_DATA = 'setInitialData';
export const SET_QUIZ_ELEMENT_TOP_POSITION = 'setQuizElementTopPosition';
export const SET_CURRENT_TAB = 'setCurrentTab';
export const CHECK_IF_CAN_MOVE_FORWARD = 'checkIfCanMoveForward';
export const GO_NEXT = 'next';
export const GO_BACK = 'back';
export const SET_AWARD_SCREEN_REACHED = 'setAwardScreenReached';
export const SHOW_AWARD_SCREEN = 'showAwardScreen';
