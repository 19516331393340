import taggingUtils from './utils/tagging';
import {
  scrollToQuiz,
  isSectionValid,
  nextSection,
  previousSection,
  sendAnswers,
} from './utils/quiz';
import {
  MUTATE,
  SET_CURRENT_SECTION,
  SET_SECTION_VALIDATED,
  SET_SECTION_REACHED,
  SET_SECTION_LOCKED,
  SET_INITIAL_VALIDATED_SECTIONS,
  SET_INITIAL_REACHED_SECTIONS,
  SET_INITIAL_DATA,
  SET_QUIZ_ELEMENT_TOP_POSITION,
  SET_CURRENT_TAB,
  CHECK_IF_CAN_MOVE_FORWARD,
  GO_NEXT,
  GO_BACK,
  SET_AWARD_SCREEN_REACHED,
  SHOW_AWARD_SCREEN,
} from './mutation-types';

export const state = {
  apiUrl: '',
  currentIndex: 0,
  currentSection: {},
  sections: [],
  quizElementTopPosition: 0,
  showAwardScreen: false,
  awardScreenReached: false,
  loading: false,
};

export const mutations = {
  [MUTATE](state, payload) {
    state[payload.property] = payload.with;
  },
  [SET_CURRENT_SECTION](state, payload) {
    state.currentSection = payload;
    state.currentIndex = state.sections.indexOf(payload);
  },
  [SET_SECTION_VALIDATED](state, payload) {
    state.currentSection.validated = payload;
  },
  [SET_SECTION_REACHED](state) {
    state.currentSection.reached = true;
  },
};

export const actions = {
  [SET_INITIAL_DATA]({ commit, dispatch }, payload) {
    const { sections, apiUrl } = payload;
    commit(MUTATE, { property: 'apiUrl', with: apiUrl });
    commit(MUTATE, { property: 'sections', with: sections });
    dispatch(SET_INITIAL_VALIDATED_SECTIONS);
    dispatch(SET_INITIAL_REACHED_SECTIONS);
    dispatch(SET_CURRENT_SECTION);
  },
  [SET_INITIAL_VALIDATED_SECTIONS]({ commit, state }) {
    const sections = state.sections.map((section) => {
      section.validated = isSectionValid(section);
      return section;
    });
    commit(MUTATE, { property: 'sections', with: sections });
  },
  [SET_INITIAL_REACHED_SECTIONS]({ commit, state }) {
    const firstInvalidSection = state.sections.filter((section) => !section.validated)[0];
    const sections = state.sections.map((section, i) => {
      if (i === 0 || i <= state.sections.indexOf(firstInvalidSection)) {
        section.reached = true;
      }
      return section;
    });
    commit(MUTATE, { property: 'sections', with: sections });
  },
  [SET_CURRENT_SECTION]({ commit, state }) {
    commit(
      SET_CURRENT_SECTION,
      state.sections.find((section) => section.active) || state.sections[0]
    );
  },
  [SET_QUIZ_ELEMENT_TOP_POSITION]({ commit }, payload) {
    commit(MUTATE, { property: 'quizElementTopPosition', with: payload });
  },
  [SHOW_AWARD_SCREEN]({ commit }) {
    commit(MUTATE, { property: 'showAwardScreen', with: true });
    setTimeout(() => {
      scrollToQuiz(state.quizElementTopPosition);
    }, 500);
  },
  [SET_AWARD_SCREEN_REACHED]({ commit }) {
    const sections = state.sections.map((section, i) => {
      if (i === state.currentIndex) {
        section.skipAwardScreen = true;
      }
      return section;
    });
    commit(MUTATE, { property: 'sections', with: sections });
    const next = nextSection(state.sections, state.currentSection);
    commit(SET_CURRENT_SECTION, next);
    commit(SET_SECTION_REACHED);
    scrollToQuiz(state.quizElementTopPosition);
    commit(MUTATE, { property: 'showAwardScreen', with: false });
  },
  [SET_CURRENT_TAB]({ commit, state }, payload) {
    const section = state.sections.find((section) => section.id === payload);
    commit(SET_CURRENT_SECTION, section);
  },
  [SET_SECTION_LOCKED]({ commit, state }) {
    const sections = state.sections.map((section, i) => {
      if (i > state.currentIndex) {
        section.reached = false;
      }
      return section;
    });
    commit(MUTATE, { property: 'sections', with: sections });
  },
  [CHECK_IF_CAN_MOVE_FORWARD]({ commit, dispatch, state }) {
    commit(SET_SECTION_VALIDATED, isSectionValid(state.currentSection));
    dispatch(SET_SECTION_LOCKED);
  },
  [GO_NEXT]({ commit, dispatch, state }) {
    commit(MUTATE, { property: 'loading', with: true });
    const currentHasAward = !state.currentSection.skipAwardScreen && state.currentSection.award;
    const next = nextSection(state.sections, state.currentSection);

    sendAnswers(state.apiUrl, state.sections, state.currentSection.id)
      .then((response) => {
        commit(MUTATE, { property: 'loading', with: false });
        taggingUtils.pushTaggingEvents(state.currentSection);

        if (response.AwardSuccess && currentHasAward) {
          dispatch(SHOW_AWARD_SCREEN);
        } else if (response.QuizUpdateSuccess && next) {
          commit(SET_CURRENT_SECTION, next);
          commit(SET_SECTION_REACHED);
          scrollToQuiz(state.quizElementTopPosition);
        } else if (response.QuizUpdateSuccess) {
          // Last question
          window.location = state.currentSection.redirectsToUrl;
        }
      })
      .catch((err) => console.error(err));
  },
  [GO_BACK]({ commit, state }) {
    const previous = previousSection(state.sections, state.currentSection);
    if (previous) {
      commit(SET_CURRENT_SECTION, previous);
      scrollToQuiz(state.quizElementTopPosition);
    }
  },
};

export const getters = {
  sections: (state) => state.sections,
  currentIndex: (state) => state.currentIndex,
  currentSection: (state) => state.currentSection,
  awardScreenReached: (state) => state.awardScreenReached,
  showAwardScreen: (state) => state.showAwardScreen,
  loading: (state) => state.loading,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
