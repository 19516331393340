<template>
  <div :class="['oap-beauty-exit', { 'oap-beauty-exit--show-award': showAward }]">
    <button class="oap-beauty-exit__trigger" :aria-label="closeBtnLabel" @click="toggleModal(true)">
      <svg class="icon" aria-hidden="true">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
      </svg>
    </button>
    <OapPopup
      title="Exit Beauty Profile"
      :open="open"
      :hide-overflow="true"
      :aside="false"
      @closed="toggleModal(false)"
    >
      <div ref="exitContent" class="oap-beauty-exit__content">
        <slot name="exit-overlay-title-description" />
        <button
          ref="exitButton"
          v-tag:useractionevent.dom
          class="oap-button -primary"
          data-tag-wrapper
          data-tag-category="my account"
          :data-tag-action="taggingAction()"
          :data-tag-label="taggingLabel()"
          @click="toggleModal(false)"
        >
          <slot name="exit-overlay-continue-cta-label" />
        </button>
        <slot name="exit-overlay-quit-cta-label" />
        <slot name="exit-overlay-image" />
      </div>
      <template #close>
        <button class="oap-popup__close" :aria-label="closeBtnLabel" @click="toggleModal(false)">
          <svg class="icon" aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
          </svg>
        </button>
      </template>
    </OapPopup>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import OapPopup from '../../../../OapPopup/code/Scripts/components/OapPopup.vue';

export default {
  name: 'OapBeautyExit',
  components: {
    OapPopup,
  },
  directives: {
    eventBus,
  },
  props: {
    showAward: { type: Boolean, required: true },
    closeBtnLabel: { type: String, required: true },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleModal(status) {
      this.open = status;
    },
    taggingAction() {
      return (
        this.$refs.exitContent &&
        this.$refs.exitContent.querySelector('.oap-beauty-exit__heading') &&
        this.$refs.exitContent.querySelector('.oap-beauty-exit__heading').innerText
      );
    },
    taggingLabel() {
      return this.$refs.exitButton && this.$refs.exitButton.innerText;
    },
  },
};
</script>
