<template>
  <div ref="award">
    <div :id="currentSectionId" class="oap-beauty-award-wrapper">
      <div class="oap-beauty-award">
        <div class="oap-beauty-award__hero">
          <div
            class="oap-beauty-award__heading oap-beauty-award__heading--first"
            v-html="award.title"
          ></div>
          <div class="oap-beauty-award__image">
            <img v-srcset="award.image.srcset" :alt="award.image.alt" />
          </div>
        </div>
        <ul class="oap-beauty-award__content">
          <li>
            <div class="oap-beauty-award__badge">
              <img v-srcset="award.earnedCouponImage.srcset" :alt="award.earnedCouponImage.alt" />
            </div>
            <div class="oap-beauty-award__heading" v-html="award.earnedCouponTitle"></div>
            <a
              v-if="award.earnedCouponLink"
              v-tag:useractionevent.dom
              :href="award.earnedCouponLink.url"
              data-tag-wrapper
              data-tag-category="my account"
              :data-tag-action="removeTags(award.earnedCouponTitle)"
              :data-tag-label="award.earnedCouponLink.label"
              class="oap-beauty-award__link oap-beauty-award__trigger"
            >
              {{ award.earnedCouponLink.label }}
            </a>
          </li>
          <li>
            <div v-if="award.nextCouponImage" class="oap-beauty-award__badge">
              <img v-srcset="award.nextCouponImage.srcset" :alt="award.nextCouponImage.alt" />
            </div>
            <div
              v-if="award.nextCouponTitle"
              class="oap-beauty-award__heading"
              v-html="award.nextCouponTitle"
            ></div>
            <p v-if="award.nextCouponCopyText">
              {{ award.nextCouponCopyText }}
            </p>
            <button
              v-if="award.keepGoingButtonLabel"
              v-tag:useractionevent.dom
              class="oap-button -primary oap-beauty-award__trigger"
              data-tag-wrapper
              data-tag-category="my account"
              :data-tag-action="removeTags(award.earnedCouponTitle)"
              :data-tag-label="award.keepGoingButtonLabel"
              @click="$emit('keepGoing')"
            >
              <span class="oap-button__label">
                {{ award.keepGoingButtonLabel }}
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { srcset } from '@Foundation/directives/srcset/srcset';

export default {
  name: 'OapBeautyAward',
  directives: {
    srcset,
  },
  props: {
    currentSectionId: { type: String, required: true },
    award: { type: Object, required: true },
  },
  data() {
    return {
      header: null,
    };
  },
  mounted() {
    const firstbtn =
      this.$refs.award && this.$refs.award.querySelectorAll('.oap-beauty-award__trigger')[0];
    if (firstbtn) {
      firstbtn.focus();
    }

    this.header = document.querySelector('.articleHeader');

    const currentAwardSection = document.getElementById(this.currentSectionId);

    if (currentAwardSection) {
      currentAwardSection.style.display = 'block';
    }

    if (this.header) {
      this.header.style.display = 'none';
    }
  },
  beforeUnmount() {
    if (this.header) {
      this.header.style.display = 'block';
    }
  },
  methods: {
    removeTags(str) {
      if (str === null || str === '') {
        return false;
      } else {
        str = str.toString();
      }
      return str.replace(/(<([^>]+)>)/gi, '');
    },
  },
};
</script>
