<template>
  <div class="quiz-main-container">
    <div class="quiz-title-container">
      <h2 class="quiz-title2 heading2">
        {{ title }}
        <span>{{ subtitle }}</span>
      </h2>
      <p class="quiz-description">{{ description }}</p>
    </div>
    <div
      v-if="showQuestionAndAnswer"
      class="quiz-queAns-container"
      :style="{ 'background-image': `url(${questionAnswerBackgroundImage})` }"
    >
      <div class="quiz-questions">{{ question }}</div>
      <ul class="quiz-queAns-list-container">
        <li class="quiz-queAns-list-option">
          <label
            for="7000_val"
            class="quiz-queAns-list-option-label"
            :class="{ active: answer1Selected }"
            @click="answerSelected('1')"
          >
            <input
              id="7000_val"
              type="radio"
              name="plastic"
              :value="answer1"
              class="quiz-queAns-list-option-input"
              @change="onChange($event)"
            />
            <span>{{ answer1 }}</span>
          </label>
        </li>
        <li class="quiz-queAns-list-option">
          <label
            for="2000_val"
            class="quiz-queAns-list-option-label"
            :class="{ active: answer2Selected }"
            @click="answerSelected('2')"
          >
            <input
              id="2000_val"
              type="radio"
              name="plastic"
              :value="answer2"
              class="quiz-queAns-list-option-input"
              @change="onChange($event)"
            />
            <span>{{ answer2 }}</span>
          </label>
        </li>
        <li class="quiz-queAns-list-option">
          <label
            for="5000_val"
            class="quiz-queAns-list-option-label"
            :class="{ active: answer3Selected }"
            @click="answerSelected('3')"
          >
            <input
              id="5000_val"
              type="radio"
              name="plastic"
              :value="answer3"
              class="quiz-queAns-list-option-input"
              @change="onChange($event)"
            />
            <span>{{ answer3 }}</span>
          </label>
        </li>
        <li class="quiz-queAns-list-option">
          <label
            for="50000_val"
            class="quiz-queAns-list-option-label"
            :class="{ active: answer4Selected }"
            @click="answerSelected('4')"
          >
            <input
              id="50000_val"
              type="radio"
              name="plastic"
              :value="answer4"
              class="quiz-queAns-list-option-input"
              @change="onChange($event)"
            />
            <span>{{ answer4 }}</span>
          </label>
        </li>
      </ul>
      <button
        id="enableButton"
        class="quiz-queAns-btn btn button -primaryAnimated"
        :class="{ _disabled: buttonDisabled }"
        @click="VerifyQuestionnaire"
      >
        {{ buttonText }}
      </button>
    </div>
    <!--correct answer start-->
    <div
      v-if="rightAnswerCheck"
      class="quiz-correct-container"
      :style="{ 'background-image': `url(${rightAnswerBackgroundImage})` }"
    >
      <div class="quiz-result-title">
        <span>{{ rightText }}</span>
        <svg
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="18.5"
            cy="18.5"
            r="16.5"
            fill="#E32847"
            stroke="#E32847"
            stroke-width="2.88"
          />
          <path
            d="M12.5596 18.6807L16.3706 22.4916L25.1315 13.7307"
            stroke="white"
            stroke-width="2.88"
          />
        </svg>
      </div>
      <p class="quiz-result-description">{{ rightAnswerDescription }}</p>
    </div>

    <!--correct answer end-->
    <!--wrong answer start-->
    <div
      v-if="wrongAnswerCheck"
      class="quiz-wrong-container"
      :style="{ 'background-image': `url(${wrongAnswerBackgroundImage})` }"
    >
      <div class="quiz-result-title">
        <span>{{ wrongText }}</span>
        <svg
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="16.5" fill="white" stroke="white" stroke-width="4" />
          <path d="M12.7735 24.77L24.4725 13.071" stroke="black" stroke-width="4" />
          <path d="M24.9199 24.9191L13.2209 13.22" stroke="black" stroke-width="4" />
        </svg>
      </div>
      <p class="quiz-result-description">{{ wrongAnswerDescription }}</p>
    </div>
    <!--wrong answer end-->
  </div>
</template>

<script>
export default {
  name: 'OapQuiz',
  props: {
    title: { type: String, required: false },
    subtitle: { type: String, required: false },
    description: { type: String, required: false },
    question: { type: String, required: false },
    answer1: { type: String, required: false },
    answer2: { type: String, required: false },
    answer3: { type: String, required: false },
    answer4: { type: String, required: false },
    correctAnswer: { type: String, required: false },
    buttonText: { type: String, required: false },
    questionAnswerBackgroundImage: { type: String, required: true },
    rightAnswerBackgroundImage: { type: String, required: true },
    wrongAnswerBackgroundImage: { type: String, required: true },
    rightAnswerDescription: { type: String, required: false },
    wrongAnswerDescription: { type: String, required: false },
    rightText: { type: String, required: false },
    wrongText: { type: String, required: false },
  },
  data() {
    return {
      showQuestionAndAnswer: true,
      rightAnswerCheck: false,
      wrongAnswerCheck: false,
      selectedValue: '',
      answerActive: '0',
      answer1Selected: false,
      answer2Selected: false,
      answer3Selected: false,
      answer4Selected: false,
      buttonDisabled: true,
    };
  },

  methods: {
    VerifyQuestionnaire() {
      this.showQuestionAndAnswer = false;
      if (this.selectedValue.toLowerCase().trim() == this.correctAnswer.toLowerCase().trim()) {
        this.rightAnswerCheck = true;
      } else {
        this.wrongAnswerCheck = true;
      }
    },

    onChange(event) {
      this.selectedValue = event.target.value;
      if (this.selectedValue != '') {
        this.buttonDisabled = false;
      }
    },

    answerSelected(answer) {
      if (answer === '1') {
        this.answer1Selected = true;
        this.answer2Selected = false;
        this.answer3Selected = false;
        this.answer4Selected = false;
      } else if (answer === '2') {
        this.answer1Selected = false;
        this.answer2Selected = true;
        this.answer3Selected = false;
        this.answer4Selected = false;
      } else if (answer === '3') {
        this.answer1Selected = false;
        this.answer2Selected = false;
        this.answer3Selected = true;
        this.answer4Selected = false;
      } else if (answer === '4') {
        this.answer1Selected = false;
        this.answer2Selected = false;
        this.answer3Selected = false;
        this.answer4Selected = true;
      }
    },
  },
};
</script>
