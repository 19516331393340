<template>
  <div>
    <div class="hcm-container">
      <div class="hcm-wrapper">
        <h2 class="heading2 hcm-title">{{ sectionData.SectionTitle }}</h2>
        <div class="hcm-slide-wrapper">
          <div
            v-for="(question, index) in questions.StepList"
            :key="index"
            class="hcm-slide animated"
            :class="[question.Step.StepName === questionId ? 'current' : 'inActive']"
            :data-question-id="question.Step.StepName"
          >
            <ul class="hcm-inner-wrapper">
              <template v-for="answer in answers.GetOptionList">
                <li
                  v-if="question.Step.Option1 === answer.Option.OptionName"
                  @click="result(answer.Option, question.Step.StepName)"
                >
                  <div class="hcm-thumbnail-block">
                    <div class="hcm-img-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        ><img :src="answer.Option.Image" alt=""
                      /></a>
                      <img v-else :src="answer.Option.Image" alt="" />
                    </div>
                    <div class="hcm-info-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        v-html="answer.Option.Description"
                      ></a>
                      <div v-else v-html="answer.Option.Description"></div>
                    </div>
                  </div>
                </li>
                <li
                  v-if="question.Step.Option2 === answer.Option.OptionName"
                  @click="result(answer.Option, question.Step.StepName)"
                >
                  <div class="hcm-thumbnail-block">
                    <div class="hcm-img-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        ><img :src="answer.Option.Image" alt=""
                      /></a>
                      <img v-else :src="answer.Option.Image" alt="" />
                    </div>
                    <div class="hcm-info-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        v-html="answer.Option.Description"
                      ></a>
                      <div v-else v-html="answer.Option.Description"></div>
                    </div>
                  </div>
                </li>
                <li
                  v-if="question.Step.Option3 === answer.Option.OptionName"
                  @click="result(answer.Option, question.Step.StepName)"
                >
                  <div class="hcm-thumbnail-block">
                    <div class="hcm-img-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        ><img :src="answer.Option.Image" alt=""
                      /></a>
                      <img v-else :src="answer.Option.Image" alt="" />
                    </div>
                    <div class="hcm-info-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        v-html="answer.Option.Description"
                      ></a>
                      <div v-else v-html="answer.Option.Description"></div>
                    </div>
                  </div>
                </li>
                <li
                  v-if="question.Step.Option4 === answer.Option.OptionName"
                  @click="result(answer.Option, question.Step.StepName)"
                >
                  <div class="hcm-thumbnail-block">
                    <div class="hcm-img-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        ><img :src="answer.Option.Image" alt=""
                      /></a>
                      <img v-else :src="answer.Option.Image" alt="" />
                    </div>
                    <div class="hcm-info-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        v-html="answer.Option.Description"
                      ></a>
                      <div v-else v-html="answer.Option.Description"></div>
                    </div>
                  </div>
                </li>
                <li
                  v-if="question.Step.Option5 === answer.Option.OptionName"
                  @click="result(answer.Option, question.Step.StepName)"
                >
                  <div class="hcm-thumbnail-block">
                    <div class="hcm-img-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        ><img :src="answer.Option.Image" alt=""
                      /></a>
                      <img v-else :src="answer.Option.Image" alt="" />
                    </div>
                    <div class="hcm-info-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        v-html="answer.Option.Description"
                      ></a>
                      <div v-else v-html="answer.Option.Description"></div>
                    </div>
                  </div>
                </li>
                <li
                  v-if="question.Step.Option6 === answer.Option.OptionName"
                  @click="result(answer.Option, question.Step.StepName)"
                >
                  <div class="hcm-thumbnail-block">
                    <div class="hcm-img-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        ><img :src="answer.Option.Image" alt=""
                      /></a>
                      <img v-else :src="answer.Option.Image" alt="" />
                    </div>
                    <div class="hcm-info-block">
                      <a
                        v-if="answer.Option.IsItLastOption === true"
                        :href="answer.Option.PageLink.Url"
                        :target="answer.Option.PageLink.Target"
                        :class="answer.Option.PageLink.Class"
                        :title="answer.Option.PageLink.Title"
                        v-html="answer.Option.Description"
                      ></a>
                      <div v-else v-html="answer.Option.Description"></div>
                    </div>
                  </div>
                </li>
              </template>
              <li
                v-if="index !== 0"
                class="hcm-backtoParent-wrapper"
                @click="previous(question.Step.StepName)"
              >
                <div class="hcm-backtoParent-block">
                  <span class="hcm-backtoParent-button">{{ sectionData.BackButton }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductModule',
  props: {
    questionData: { type: Object, required: true },
    answerData: { type: Object, required: true },
    sectionData: { type: Object, required: true },
  },
  data() {
    return {
      questions: this.questionData,
      answers: this.answerData,
      questionId: 'Step 1',
      questionCount: 0,
    };
  },
  methods: {
    result(answer) {
      if (answer.IsItLastOption === false) {
        this.questionId = answer.NextQuestion;
        this.questionCount++;
        setTimeout(() => {
          var productModuleSlide = document.querySelectorAll('.hcm-slide');
          Array.prototype.forEach.call(productModuleSlide, (item) => {
            if (item.classList.contains('current') === true) {
              item.classList.add('slideInRight');
              item.classList.remove('slideInleft');
            } else {
              item.classList.add('slideOutLeft');
              item.classList.remove('slideOutRight');
            }
          });
        }, 10);
      }
    },
    previous(StepName) {
      let tempArray = this.questions.StepList;
      let tempQuestionId;
      this.answers.GetOptionList.forEach(function (answer) {
        if (StepName == answer.Option.NextQuestion) {
          tempArray.forEach(function (question) {
            if (
              answer.Option.OptionName == question.Step.Option1 ||
              answer.Option.OptionName == question.Step.Option2 ||
              answer.Option.OptionName == question.Step.Option3 ||
              answer.Option.OptionName == question.Step.Option4 ||
              answer.Option.OptionName == question.Step.Option5 ||
              answer.Option.OptionName == question.Step.Option6
            ) {
              tempQuestionId = question.Step.StepName;
            }
          });
        }
      });

      this.questionId = tempQuestionId;
      if (this.questionCount > 0) {
        this.questionCount--;
      }
      setTimeout(() => {
        var productModuleSlide = document.querySelectorAll('.hcm-slide');
        Array.prototype.forEach.call(productModuleSlide, (item) => {
          if (item.classList.contains('current') === true) {
            item.classList.remove('slideInRight');
            item.classList.add('slideInleft');
          } else {
            item.classList.remove('slideOutLeft');
            item.classList.add('slideOutRight');
          }
        });
      }, 10);
    },
  },
};
</script>
