<template>
  <div></div>
</template>
<script>
export default {
  name: 'DatalayerUpdate',
  props: {
    extraParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActivated: false,
    };
  },
  computed: {
    isVtoInQueryString() {
      const params = new URLSearchParams(window.location.search);
      let value = params.get('utm_medium');
      return !!(value && value === 'vto');
    },
  },
  created() {
    window.eventBus.on('activate-vto-component', () => {
      this.$nextTick(() => {
        this.isActivated = true;
      });
    });
    this.additionalDatalayer();
  },
  methods: {
    additionalDatalayer() {
      window.refapp = window.refapp || {};
      if (this.isActivated || this.isVtoInQueryString) {
        const params = this.extraParams;
        params.add.products[0].dimension49 = 'activated';
        window.refapp.mctbEcommerce = params;
        return params;
      } else {
        window.refapp.mctbEcommerce = this.extraParams;
      }
    },
  },
};
</script>
